import { useEffect, useState } from 'react';
import useSwr from 'swr';
import { noAuthFetcher } from './fetcher';

import { reportError } from './bugsnag';
import { useNotification } from './notification';
import {
  useCouponOnlyProducts,
  useLocale,
  useShippingCountry,
  useSubscriptions,
  useTranslation,
} from './hooks';
import { usePersistedState } from '../lib/hooks.persist';
import {
  findProduct,
  getDefaultImage,
  parseVariantSize,
  updateProducts,
  getSmallestVariant,
  getCheapestVariantPrice,
  findVariant,
  isDryFood,
} from './utils.products';
import { mapLocaleToShippingCountry } from './utils.locale';
import {
  Currency,
  ErrorResponse,
  ExtendedProduct,
  ImageObject,
  ProductType,
  Recommendation,
  RecommendationProductsApiResponse,
  ShippingCountry,
  Subscription,
  CartItem,
  Variant,
  VariantSalesUnit,
} from '../types';

type ProductOptions =
  | 'puppyBoxOnly'
  | 'includePuppyBox'
  | 'includeCouponOnly'
  | 'all';

export function useProducts(
  options?: Partial<Record<ProductOptions, boolean>>,
): {
  products?: ExtendedProduct[];
  loading: boolean;
} {
  const locale = useLocale();
  const couponOnlyProducts = useCouponOnlyProducts();
  const { country = mapLocaleToShippingCountry(locale), loading } =
    useShippingCountry();

  const { data, ...rest } = useSwr<RecommendationProductsApiResponse>(
    loading || !country
      ? null
      : `/api/recommendation?type=products&lang=${locale}&shippingCountry=${country}`,
    noAuthFetcher,
    { revalidateOnFocus: false },
  );
  const responseIsError = (data as ErrorResponse)?.error;

  const { puppyBox } = usePuppyBox(
    responseIsError || !Array.isArray(data) ? [] : (data as ExtendedProduct[]),
  );

  if (responseIsError) {
    return {
      loading: false,
      ...rest,
    };
  }

  let products: ExtendedProduct[] = [];

  if (data !== undefined) {
    products = data as ExtendedProduct[];
  }

  if (options?.puppyBoxOnly && puppyBox) {
    return {
      products: [puppyBox],
      loading: false,
      ...rest,
    };
  }

  if (options?.includeCouponOnly || options?.all) {
    products = [...products, ...couponOnlyProducts];
  }

  if ((options?.includePuppyBox || options?.all) && puppyBox) {
    products = [...products, puppyBox];
  }

  return {
    products: products.filter(Boolean),
    loading: !data,
    ...rest,
  };
}

type WcPuppyBoxResponse = RawPuppyBoxProduct[] | ErrorResponse;

interface RawPuppyBoxProduct {
  id: number;
  sku: string;
  price: number;
  zone_prices: {
    id: string;
    name: string;
    countries: string[];
    currency: Currency;
    price: string;
    regular_price: string;
    alvar_prices: Record<string, number>;
  }[];
  bundled_items: {
    product_id: number;
    quantity_default: number;
  }[];
}

export interface PuppyBoxVariant extends Variant {
  subscriptionItems: number[];
}

export interface PuppyBoxProduct extends ExtendedProduct {
  price: CartItem['price'];
  quantityLimitPerOrder: CartItem['quantityLimitPerOrder'];
  variants: PuppyBoxVariant[];
}

export const usePuppyBox = (
  products: ExtendedProduct[],
): {
  puppyBox?: PuppyBoxProduct;
  loading: boolean;
} => {
  const t = useTranslation();
  const { country = ShippingCountry.FI, loading } = useShippingCountry();
  const { data, ...rest } = useSwr<WcPuppyBoxResponse>(
    loading ? null : `/api/products?country=${country}`,
    noAuthFetcher,
    { revalidateOnFocus: false },
  );

  if ((data as ErrorResponse)?.error) {
    return {
      loading: false,
      ...rest,
    };
  }

  let puppyBox: PuppyBoxProduct | undefined = undefined;

  if (
    data !== undefined &&
    (data as RawPuppyBoxProduct[]).length > 0 &&
    products?.length > 0
  ) {
    const rawData = data as unknown as RawPuppyBoxProduct[];
    const useZonePrice = country !== ShippingCountry.FI;

    const variants: PuppyBoxVariant[] = rawData.map(
      ({ id, zone_prices, price, bundled_items }) => {
        const localPrice = useZonePrice
          ? zone_prices.find((zonePrice) =>
              zonePrice.countries.includes(country.toUpperCase()),
            )?.regular_price || 0
          : price;

        const bundleNormalPrice = bundled_items.reduce(
          (acc, item) =>
            acc +
            +`${findVariant(item.product_id, products)?.regularPrice || 0}` *
              item.quantity_default,
          0,
        );

        return {
          id: `puppy-box-${id}`,
          salePrice: parseFloat(`${localPrice}`),
          regularPrice: bundleNormalPrice,
          unit: VariantSalesUnit.pcs,
          size: 1,
          title: t(`puppy-box-${id}`),
          shortTitle: t(`puppy-box-${id}`),
          wordpressId: id,
          subscriptionItems: bundled_items.map((item) => item.product_id),
          pcs: 1,
          inStock: true,
          emission: null,
        };
      },
    );

    const price = getCheapestVariantPrice(variants);

    const upsellImage = {
      src:
        country === ShippingCountry.FI
          ? '/images/Puppy_upsell_FI.jpg'
          : '/images/Puppy_upsell_Nordic.jpg',
      alt: t('product.puppy_box'),
    };

    const maskedImage = {
      src:
        country === ShippingCountry.FI
          ? '/images/Puppybox_FI.png'
          : '/images/Puppybox_SE.png',
      alt: t('product.puppy_box'),
    };

    if (price) {
      const baseLine = {
        id: 'PUPPY-BUNDLE',
        wordpressId: variants[0]?.wordpressId || 0,
        type: ProductType.PuppyBox,
        heroImage: upsellImage,
        images: [upsellImage],
        title: t('product.puppy_box'),
        shortTitle: t('product.puppy_box'),
        description: t('puppy_box.description'),
        maskedImage,
      } as unknown as ExtendedProduct;

      puppyBox = {
        ...baseLine,
        price,
        oneOff: true,
        quantityLimitPerOrder: 1,
        variants,
      };
    }
  }

  return {
    puppyBox,
    loading: !data || products?.length < 1,
    ...rest,
  };
};

// Prio for targeted upselling.
// Should be prioritized first by category, then within category.
const productPrioritization: Record<string, any> = {
  treats: {
    chicken: 'ALV-202',
    fish: 'ALV-203',
    insect: 'ALV-204',
  },
  fishHeads: 'ALV-201',
  chews: {
    game: 'ALV-304',
    fish: 'ALV-305',
  },
  [ProductType.Accessory]: 'ALV-901',
  [ProductType.WetFood]: {
    chicken: 'ALV-402',
    fish: 'ALV-401',
  },
  [ProductType.Supplement]: {
    hemp: 'ALV-502',
    fish: 'ALV-501',
  },
  [ProductType.Sauce]: 'ALV-503',
};

const getRecommendationCategory = (product: ExtendedProduct): string => {
  if (Object.values(productPrioritization.chews).includes(product.id)) {
    return 'chews';
  }
  if (Object.values(productPrioritization.treats).includes(product.id)) {
    return 'treats';
  }
  if (product.id === productPrioritization.fishHeads) {
    return 'fishHeads';
  }
  return product.type;
};

// Lightweight recommendation for secondary products
const getRecommendedVariation = (ingredients: string[], cat: string) => {
  const recommendedVariations = {
    treats: 'chicken',
    chews: 'game',
    wetFood: 'chicken',
    supplement: 'fish',
  };

  if (ingredients.includes('fish')) {
    recommendedVariations.treats = 'fish';
    recommendedVariations.chews = 'fish';
    recommendedVariations.wetFood = 'fish';
  }

  if (ingredients.includes('insect') || ingredients.includes('plant')) {
    recommendedVariations.treats = 'insect';
    recommendedVariations.supplement = 'hemp';
    recommendedVariations.wetFood = 'fish';
  }

  return recommendedVariations[cat as keyof typeof recommendedVariations] || '';
};

const getRecommendedSku = (
  category: string,
  preferredIngredients: string[],
): string | undefined =>
  typeof productPrioritization[category] === 'string'
    ? productPrioritization[category]
    : productPrioritization[category][
        getRecommendedVariation(preferredIngredients, category)
      ];

const getRecommendedProduct = (sku: string, products: ExtendedProduct[]) =>
  products.find((p) => p.id === sku && p.variants.some((v) => v.inStock));

const padUpsellState = (
  initialState: UpsellRecommendationState[],
  products: ExtendedProduct[],
  subscriptionItems: Subscription['items'],
) => {
  const paddedState = products.reduce((acc, p) => {
    if (acc.length < 3 && !isDryFood(p.type)) {
      const alreadyAdded = acc.find((a) => a.id === p.wordpressId);
      if (!alreadyAdded) {
        const alreadyInSubscription = subscriptionItems.find(
          (i) => i.product_id === p.wordpressId,
        );
        if (!alreadyInSubscription) {
          const hasVariantInStock = p.variants.some((v) => v.inStock);
          if (hasVariantInStock && !alreadyInSubscription) {
            return [...acc, { id: p.wordpressId, claimed: false, busy: false }];
          }
        }
      }
    }
    return acc;
  }, initialState);

  return paddedState;
};

export const getProductRecommendation = (
  storedIds: UpsellRecommendationState[],
  products: ExtendedProduct[],
  pcsString = 'pcs',
) =>
  storedIds.reduce<UpsellRecommendation[]>((acc, { claimed, busy, id }) => {
    const product = findProduct(id, products);
    const inStockVariants = (product?.variants || []).filter((v) => v.inStock);
    const variant = getSmallestVariant(inStockVariants);
    if (product && variant) {
      return [
        ...acc,
        {
          ...product,
          heroImage:
            product.heroImage ||
            product.images[0] ||
            getDefaultImage(product.type, product.id),
          size: parseVariantSize(variant, pcsString),
          price: variant.salePrice || variant.regularPrice,
          wordpressId: variant.wordpressId,
          claimed,
          busy,
        },
      ];
    }
    return acc;
  }, []);

export interface UpsellRecommendation extends ExtendedProduct {
  heroImage: ImageObject;
  size: string;
  price: number;
  claimed: boolean;
  busy: boolean;
}

interface UpsellRecommendationState {
  id: ExtendedProduct['wordpressId'];
  claimed: boolean;
  busy: boolean;
}

export const useRecommendedUpsells = (
  dogId: number,
  subscription: Subscription,
  preferredIngredients?: Recommendation['preferredIngredients'],
) => {
  const t = useTranslation();
  const { products = [] } = useProducts();
  const { items } = subscription;

  const { updateSubscription } = useSubscriptions();
  const { showNotification } = useNotification('add-upsell-status', {
    timeout: 2500,
    sticky: true,
  });

  const [claiming, setClaiming] = useState(false);
  const [productRecommendation, setProductRecommendation] = useState<
    UpsellRecommendation[]
  >([]);

  // Persist recommendation in session, to keep recommendation to 3 products per session. Store only ids
  const [storedUpsells, setStoredUpsells] = usePersistedState<
    Record<number, UpsellRecommendationState[]>
  >('dogRecommendations', {});

  // set initial stored state
  useEffect(() => {
    if (subscription && preferredIngredients && products.length > 0) {
      if (!storedUpsells[dogId] || storedUpsells[dogId].length === 0) {
        const categoriesInSubscription = items.reduce<string[]>((acc, item) => {
          const product = findProduct(item.product_id, products);
          if (product) {
            const category = getRecommendationCategory(product);
            if (category && !acc.includes(category)) {
              return [...acc, category];
            }
          }
          return acc;
        }, []);

        const recommendedCategories = Object.keys(productPrioritization)
          .filter((category) => !categoriesInSubscription.includes(category))
          .slice(0, 3);

        const initialState = recommendedCategories.reduce((acc, category) => {
          const sku = getRecommendedSku(category, preferredIngredients) || '';
          const product = getRecommendedProduct(sku, products);

          return product
            ? [...acc, { id: product.wordpressId, claimed: false, busy: false }]
            : acc;
        }, [] as UpsellRecommendationState[]);

        if (
          !(
            initialState.length === 0 &&
            storedUpsells[dogId] &&
            storedUpsells[dogId].length === 0
          )
        ) {
          setStoredUpsells({
            ...storedUpsells,
            [dogId]: padUpsellState(initialState, products, items),
          });
        }
      }
    }
  }, [dogId, subscription, preferredIngredients, products]);

  // Keep products up to date with stored state
  useEffect(() => {
    if (storedUpsells[dogId] && products.length > 0) {
      const productReco = getProductRecommendation(
        storedUpsells[dogId],
        products,
        t('units.pcs'),
      );
      setProductRecommendation(productReco);
    }
  }, [storedUpsells[dogId]]);

  // keep claimed state up to date with changes to subscription
  useEffect(() => {
    if (storedUpsells[dogId] && storedUpsells[dogId].length > 0 && !claiming) {
      const p = storedUpsells[dogId].map((p) => ({
        ...p,
        claimed: !!subscription.items.find((i) => i.product_id === p.id),
      }));
      setStoredUpsells({ ...storedUpsells, [dogId]: p });
    }
  }, [subscription]);

  // Update subscription
  // Keep track of claimed and busy products
  // Provide notification on outcome
  // Report if anything goes wrong
  const claimUpsell = async (
    wordpressId: ExtendedProduct['wordpressId'],
    quantity: number,
    originallyRecommendedId?: ExtendedProduct['wordpressId'], // use this in case user is claiming a different variant of product
  ) => {
    try {
      const idInState = originallyRecommendedId || wordpressId;
      setClaiming(true);
      setStoredUpsells({
        ...storedUpsells,
        [dogId]: storedUpsells[dogId].map((p) => ({
          ...p,
          busy: p.id === idInState ? true : p.busy,
        })),
      });

      const res = await updateSubscription({
        ...subscription,
        items: updateProducts(subscription.items, quantity, wordpressId),
      });

      if (res) {
        setStoredUpsells({
          ...storedUpsells,
          [dogId]: storedUpsells[dogId].map((p) => ({
            ...p,
            id: p.id === idInState ? wordpressId : p.id,
            claimed: p.id === idInState || p.claimed,
            busy: p.id === idInState ? false : p.busy,
          })),
        });
        showNotification(t('notification.product_added'), undefined, {
          type: 'success',
        });
      } else {
        showNotification(
          t('order.errors.something_went_wrong', { type: 'error' }),
        );
        setStoredUpsells({
          ...storedUpsells,
          [dogId]: storedUpsells[dogId].map((p) => ({
            ...p,
            busy: p.id === idInState ? false : p.busy,
          })),
        });
      }
      setClaiming(false);
    } catch (e) {
      reportError(e);
      showNotification(
        t('order.errors.something_went_wrong', { type: 'error' }),
      );
      setStoredUpsells({
        ...storedUpsells,
        [dogId]: storedUpsells[dogId].map((p) => ({
          ...p,
          busy: p.id === wordpressId ? false : p.busy,
        })),
      });
    }
  };

  return {
    recommendedUpsells: productRecommendation,
    claimUpsell,
  };
};
