export default {
  button_text: {
    browse_all_products: 'Selaa koko valikoimaa',
    selected: 'Valittu',
    select_product: 'Valitse',
    faq: 'Usein kysyttyä',
    cancel: 'Peruuta\n',
    continue: 'Jatka',
    product_details: 'Tuotetiedot',
    sign_in: 'Kirjaudu sisään',
    sign_up: 'Luo käyttäjätunnus',
    view_alternatives: 'Selaa vaihtoehtoja',
    add_to_subscription: 'Lisää tilaukselle',
    updating_subscription: 'Päivitetään tilausta',
    click_to_add_coupon: 'Lisää alennuskoodi',
    configure: 'Muokkaa',
    switch_to_one_time_order: 'Klikkaa tästä!',
    change_delivery_schedule: 'Muuta toimitusväliä',
    order_overview: 'Tilauksen yhteenveto',
    skip_next_order: 'Skippaa seuraava toimitus',
    edit_subscription: 'Muokkaa tilauksesi\n',
    read_more: 'Lue lisää',
    confirm: 'Vahvista',
    view_change_product: 'Tuotetiedot / vaihda',
  },
  campaign: {
    share_referral: {
      copy: 'Kopioi linkkisi jaettavaksi',
      success: 'Kopioitu! Valmis jaettavaksi',
    },
    action: {
      add_to_order: 'Lisää tilaukseen',
    },
  },
  delivery_prices: {
    price_over_x_money: '{{discounted}} yli {{spend}} toimituksiin\\n',
  },
  discount: {
    applies_to_all: '{{discount}} mihin vaan tuotteeseen',
    applies_to_categories: '{{discount}} alennus tuoteryhmille: {{categories}}',
    product_category: {
      '2262': 'kuivaruoat',
      '3211': '',
      '4567': 'lisukkeet',
      '4933': '',
      '5011': 'puruluut',
      '5013': 'puruluut',
      '5015': 'kakkapussit',
      '5021': 'herkut',
      '5023': 'herkut',
      '5029': 'kuivaruoat',
      '5031': 'kuivaruoat',
      '5035': 'öljyt',
      '5037': 'öljyt',
      '5041': 'lisukkeet',
      '5043': 'lisukkeet',
      '5183': 'tarvikkeet',
      '6540': 'kuivaruoat suomesta',
      '6705': 'kuivaruoat',
      '7029': '',
      '7671': 'lisukkeet',
      '7677': 'herkut',
      '7681': 'puruluut',
      '7687': 'tarvikkeet',
      '7693': 'kuivaruoat',
      '7697': 'lisukkeet',
      '7814': 'tarvikkeet',
      '7818': 'lisukkeet',
      '8262': 'kombot',
      '8264': 'kombot',
      '8266': 'kombot',
      '8349': '',
      '8383': 'lelut',
      '8386': 'lelut',
      '8388': 'lelut',
      '8747': '',
      '8749': '',
      wetFood: 'märkäruoat',
      generic_categories: 'tuotteet',
      snack: 'herkut',
    },
    applies_to_category: 'Alennus koskee {{category}}-kategorian tuotteita.',
    applies_to_multiple_product_names:
      '{{discount}} alennus seuraavista tuotteista: {{productList}}. Alennus max. {{productLimit}} tuotteelle.\n',
  },
  discounts: {
    dog_names_discounts: 'Lisätyt & aktiiviset edut ',
  },
  free_shipping: {
    included: '<b>Ml. ilmainen kuljetus</b>',
    over_x_money: '<b>Ilmainen kuljetus</b> yli {{money}} toimituksiin',
    available:
      '<b>Ilmainen kuljetus valittavissa - mene profiiliin vaihtaaksesi kuljetustapa </b>',
  },
  frontpage_notification: {
    shipping_price_changes: {
      banner:
        'Muutoksia kuljetusmaksuissa! Vaihda toimitustapaa pitääksesi ilmainen kuljetus. Lue lisää\\n',
    },
  },
  label: {
    choose_product: 'Valitse tuote',
    delivery: 'Kuljetus',
    price: 'Hinta',
    product_category: 'Tuotekategoria',
    quantity: 'Lukumäärä',
    size: 'Koko',
    variant: 'Malli',
  },
  my_pack: {
    get_recommendation: 'Katso suositus ja aloita tilaus',
    recommended_diet_plan: {
      explanation:
        'Terveellistä ja vastuullista. 💚 Muistathan päivittää tiedot, jos {{dogName}}n tarpeet muuttuvat!',
      header: '{{dogName}}-koiran räätälöity ruokavalio',
    },
    recommended_products: 'Suositellut tuotteet\n',
  },
  orders: {
    load_all_receipts: 'Näytä kaikki',
  },
  price: {
    free: 'Ilmainen',
    total: 'Yhteensä',
    from_per_kg: 'Alkaen {{price}}/kg',
    starting_from: 'alk. {{price}}',
    from: 'alk. {{price}}',
  },
  product_info: {
    benefits: 'Terveyshyödyt',
    carbon_pawprint: 'Hiilitassunjälki',
    ingredients: 'Raaka-aineet',
    nutrients: 'Ravintoarvot',
    complexes: 'Terveyshyödyt',
  },
  recipe: {
    all_ingredients: {
      title: 'Raaka-aineluettelo',
    },
    nutritional_content: 'Tarkat ravintotiedot',
    additional_benefits_title: 'Lisäksi myös:',
    needs_covered_by_benefits_title: 'Tämä resepti sopii:',
  },
  recommendation_group: {
    good: 'Hyvä sopivuus',
    notSuitable: 'Huono sopivuus',
    recommended: 'Suositeltu',
  },
  subscription_details: {
    included_products: 'Tuotteet\\n',
    plus_shipping: '+ toimitusmaksu',
  },
  targeted_upsell: {
    section_heading: 'Ehdottaisimme {{dogName}}-koiran pakettiin myös:',
  },
  checkout: {
    account_info: 'Profiilin tiedot',
    add_payment_methods: 'Lisää maksutiedot',
    add_shipping_methods: 'Lisää toimitustiedot',
    delivery_method: 'Kuljetustapa',
    delivery_method_text:
      'Miten ja minne haluaisit toimituksesi kuljetettavan?',
    do_order: 'Tee tilaus',
    order: 'Tilaus',
    payment_info: 'Maksutiedot',
    shipping_info: 'Toimitustiedot',
    account: 'Luo tunnus',
    account_set_up: 'Luo käyttäjätunnus',
    verify_shipping_info: 'Vahvista osoite',
    add_shipping_method: 'Lisää toimitustiedot\n',
    confirm_subscription: 'Tilaa nyt',
  },
  edit_order: {
    quantity_lower_than_recommended:
      'Hau! Tarkista haluatko lyhentää toimitusväliä.',
    quantity_higher_than_recommended:
      'Hau! Tarkista haluatko pidentää toimitusväliä. ',
  },
  emission_card: {
    header: 'Ruokavalion ilmastovaikutus',
    kg_saved_per_year_with_subscription: '{{dogName}} säästää {{kg}} kg CO2e',
    percent_saved:
      'Hiilitassunjälki on <b>{{percent}}% vähemmän</b> kuin premium-kuivaruokien keskiarvo. Ja mitä jää jäljelle, kompensoimme.',
    savings_equal_to_times_sauna:
      'vuodessa vaihtamalla alvareihin. Se vastaa {{timesSauna}} sähkösaunan lämmityskertaa!',
    by_joining_alvar_dog_name_saves_kg:
      'Alvarin matkassa {{dogName}} ja sinä säästätte noin',
    kg_co2_per_year: '{{kg}} kg CO2 vuodessa',
    this_equals_times_sauna:
      'Tämä vastaa {{timesSauna}} sähkösaunan lämmityskertaa.',
  },
  forms: {
    errors: {
      invalid_phone_number: 'Puhelinnumero voi sisältää vain numeroita.',
      invalid_phone_code: 'Virheellinen maatunnus',
    },
  },
  macro: {
    fat: 'Rasvaa',
    fiber: 'Kuitua',
    protein: 'Proteiinia',
    energy: 'kcal energiaa / 100 g\n',
    kcal: 'kcal energiaa / 100 g\n',
  },
  macros: {
    fat: 'Rasvaa',
    fiber: 'Kuitua',
    kcal: 'Kcal',
    protein: 'Proteiinia',
  },
  order_hero: {
    button_text: {
      edit_order: 'Muokkaa tilausta',
    },
    status: {
      active: 'Aktiivinen',
    },
  },
  product_options: {
    recommended: 'suositus',
  },
  'puppy-box-423575': 'Kalaversio',
  'puppy-box-423589': 'Kanaversio',
  'puppy-box-423651': 'Kanaversio',
  'puppy-box-423652': 'Kalaversio',
  'puppy-box-423838': 'Kalaversio',
  'puppy-box-423857': 'Kanaversio',
  starting_price: {
    from_per_kilo: 'Alkaen<br/>{{startingPrice}}<br/>/kg',
  },
  thank_you: 'Kiitos',
  wrapped: {
    alvar_intro: {
      body: 'Kiitos kun minimoit kanssamme hiilitassunjälkeä tänä vuonna. Ylpeänä esittelemme: koirasi CO2-säästöt.',
      heading: 'Hei hei 2022 🐾',
    },
    dog_names_year_wrapped: '{{dogName}}: 2022 paketissa',
    download_image: 'Lataa kuva käyttöösi',
    home_page_notification: {
      body: 'Kurkkaa kuinka paljon CO2-päästöjä olette vähentäneet vuonna 2022. 🐾',
      button: 'Katso kooste',
      heading: 'Vuosi paketissa!',
    },
    how_did_we_calculate: {
      body: '<li>Summasimme kuinka paljon Alvar-kuivaruokaa {{dogName}} on tilannut tänä vuonna. Raportti kattaa vain nappulan – toistaiseksi. </li><li> Laskimme reseptin CO2-päästöt </li><li> Vertasimme päästöjä premium-kuivaruoan keskiarvoon (The global environmental paw print of pet food, Alexander et al. 2020) </li><li> Vertasimme säästöä sähkösaunan lämmittämisen päästöihin (OpenCO2.net)</li> <li> Päästöjen vähentämisen lisäksi jäljelle jäänyt hiilitassunjälki on kompensoitu. 💚</li> <br/><p>P.S. Emme taltioi kuvaasi minnekään. </p> <p>P.P.S. Hyvää Uutta Vuotta! </p>',
      heading: 'Laskeaksemme tämän numeron, me:',
    },
    intro:
      'Lisää kuva koirastasi, tallenna kuva tai ota screenshot, ja jaa se somessa (tägää @alvarpet)!',
    sorry_no_orders:
      'Pahoittelut, emme löytäneet tältä vuodelta tilauksia koirallesi.',
    upload_image: 'Lisää kuva koirastasi',
    upload_new_image: 'Vaihda kuvaa',
  },
  form: {
    labels: {
      email: 'Sähköposti',
    },
    placeholder: {
      autocomplete: 'Aloita kirjoittamalla',
      autocomplete_disabled: 'Rotua ei löytynyt',
      birthday: 'pv.kk.vuosi',
      custom_breed_name: 'Puuttuva rotu',
      day_format: 'pv',
      email: 'esimerkki@sahkoposti.fi',
      month_format: 'kk',
      select: 'Valitse...',
      year_format: 'vvvv',
      coupon_field: 'Lisää koodi tähän',
    },
    errors: {
      must_include_diet_item:
        'Kai {{dogName}} jotain syö? Valitsethan ainakin yhden ruokakategorian!',
      required: 'Pakollinen',
      invalid_phone_code: 'Virheellinen maatunnus',
      dog_weight: {
        too_high: 'Paino ei voi olla yli 200 kg',
        too_low: 'Painon oltava yli 0 kg',
      },
      email_already_in_use: 'Sähköposti on jo käytössä',
      email_already_in_use_please_login:
        'Sähköposti on jo käytössä, kirjaudu sisään:',
      invalid_date: 'Virhe päivämäärässä',
      invalid_day: 'Virhe päivässä',
      invalid_month: 'Virhe kuukaudessa',
      month_only_has_x_days: 'Kys. kuussa on vain {{days}} päivää',
      must_be_number: 'Numerona kiitos!',
      no_negative_allowed: 'Arvon tulee olla plussan puolella.',
      must_feed_dry_food:
        'Alvar-ruokavalioon tulee kuulua kuivaruoka, lisääthän sen mukaan ☺️',
      no_options_available: 'Sopivia vaihtoehtoja ei valitettavasti löytynyt.',
      not_valid_email: 'Tarkasta sähköposti',
      not_valid_postcode: 'Postinumero ei näytä oikealta',
      passwords_not_match: 'Salasanat eivät täsmää',
      postal_code_needed: 'Postinumero vaaditaan ensin',
      postcode_needed: 'Postinumero vaaditaan ensin',
      recommendation_engine_create_account_token_invalid:
        'Voi ei – istunto on vanhentunut. Ole hyvä ja',
      recommendation_engine_create_account_token_invalid_link_text:
        'aloita alusta',
      sum_too_high: 'Summa ei voi ylittää 100%',
      sum_too_low: 'Summan tulee olla 100%',
      too_short_password: 'Salasana on liian lyhyt',
    },
    warning: {
      unsaved_changes:
        'Tietoja ei ole tallennettu! Oletko varma, että haluat poistua?',
    },
    loading: 'Ladataan valintoja...',
    delivery: {
      errors: {
        dog_weight: {
          too_high: 'Paino ei voi olla yli 200 kg',
          too_low: 'Painon oltava yli 0 kg',
          email_already_in_use: 'Sähköposti on jo käytössä',
          email_already_in_use_please_login:
            'Sähköposti on jo käytössä, kirjaudu sisään:',
          invalid_date: 'Virhe päivämäärässä',
          invalid_day: 'Virhe päivässä',
          invalid_month: 'Virhe kuukaudessa',
          month_only_has_x_days: 'Kys. kuussa on vain {{days}} päivää',
          must_be_number: 'Numerona kiitos!',
          must_feed_dry_food:
            'Alvar-ruokavalioon tulee kuulua kuivaruoka, lisääthän sen mukaan ☺️',
          no_negative_allowed: 'Arvon tulee olla plussan puolella.',
          no_options_available:
            'Sopivia vaihtoehtoja ei valitettavasti löytynyt.',
          not_valid_email: 'Tarkasta sähköposti',
          not_valid_postcode: 'Postinumero ei näytä oikealta',
          passwords_not_match: 'Salasanat eivät täsmää',
          postal_code_needed: 'Postinumero vaaditaan ensin',
          postcode_needed: 'Postinumero vaaditaan ensin',
          required: 'Pakollinen',
          sum_too_high: 'Summa ei voi ylittää 100%',
          too_short_password: 'Salasana on liian lyhyt',
        },
      },
      matkahuolto: 'Matkahuolto',
      post: 'Noutopiste',
      submitted_notification: 'Tallennettu onnistuneesti!',
      home: 'Kotiinkuljetus',
      posti: 'Posti',
      budbee: 'Budbee',
      dhl: 'DHL',
      bring: 'Bring',
      postnord: 'PostNord',
      instabox: 'Instabox',
    },
    contact: {
      submit: 'Lähetä',
      success: {
        heading: 'Kiitos palautteestasi!',
        message:
          'Kiitos viestistäsi, käsittelemme sen mahdollisimman pian. Huom! Ethän laita samasta aiheesta varmuuden vuoksi sähköpostia - ellei mieleesi juolahda jotakin, jonka unohdit tässä viestissäsi mainita!\\n\\nArvostamme suuresti jokaista saamaamme huomiota, kysymystä, ehdotusta ja toivetta, sillä ne auttavat meitä kehittämään Alvaria.\\n\\n',
      },
    },
    submit: 'Lähetä',
    confirm: 'Vahvista',
    choose: 'Valitse',
    submitted_notification: 'Muutokset tallennettu onnistuneesti',
    free_word_label: 'Vapaa sana! Arvostamme palautetta.',
    submit_new_password: 'Lähetä uusi salasana',
    submission_failed:
      'Jokin meni pieleen! Yritä uudelleen ja jos pulma ei ratkea, ota yhteys asiakastukeen.',
  },
  dog: {
    gender: {
      male: 'Uros',
      female: 'Naaras',
    },
    diet_composition: {
      intro:
        'Kerro teidän nykyisestä ruokarutiinista! Käytämme tietoa suosituksemme perustana. <br/> Oletusarvo on 100% kuivaruokaa – säädä osuuksia alla.',
    },
    intro_text:
      'Luomme tietojen pohjalta hänelle räätälöidyn ruokavalion kokeilupakettiisi.',
    labels: {
      diet_items: '{{dogName}}-koiran nykyiseen ruokavalioon kuuluu',
      birthday_field: {
        day: 'päivä',
        hint: 'Esim. 19 07 2019 (kuten Alvar!)',
        legend_with_name: 'Milloin {{name}} on syntynyt?',
        legend_without_name: 'Milloin koirasi on syntynyt?',
        month: 'kuukausi',
        year: 'vuosi',
      },
      gender: 'Sukupuoli',
      special_needs: 'Erityistarpeet',
      weight: 'Aikuispaino',
      preferred_ingredients: 'Suosikkiraaka-aineet',
      activity: 'Liikunnan määrä päivässä',
      breed: 'Rotu (sekoitus? Kirjoita monirotuinen.)',
      body_condition: 'Kehotyyppi',
      birthday:
        'Syntymäpäivä (muodossa dd.mm.yyyy. Jos et tiedä koirasi tarkkaa syntymäpäivää, arvio käy hyvin!)',
      dog_name: 'Koiran nimi',
      diet_composition: {
        wet: 'Märkäruokaa (%)',
        treats: 'Herkkuja (%)',
        other: 'Muuta / kotitekoista (%)',
        dry: 'Kuivaruokaa (%)',
      },
      account_action: 'Kirjaudu sisään tai luo tunnus',
    },
    title: 'Hei, olen Alvar! Kerro meille koirastasi?',
    titles: {
      activity: 'Millaisessa kunnossa {{dogName}} on?',
      body_type: 'Kehonkunto',
      diet_composition: 'Millainen {{dogName}}-koiran nykyruokavalio on?',
      preferred_ingredients: 'Pitääkö {{name}} erityisesti jostain mauista?',
    },
    update_success: 'Muutokset tallennettu!',
    placeholder: {
      weight_estimate: 'Arvioimme',
      enter_weight: 'Lisää aikuispaino (arvio käy hyvin)',
      our_weight_estimate: 'Lisää aikuispaino (arviomme on {{estimate}} kg)',
    },
    birthday_too_old:
      'Alvaria huvittaa, mutta jotenkin epäilyttää että koirasi olisi {{age}} vuotta vanha',
    add_new: 'Lisää uusi koira',
    add_another: 'Lisää toinen koira',
    add: 'Lisää koira',
    carbon_paw_print_title: '{{dogName}}-koiran hiilitassunjälki',
    body_type_modal: {
      header: 'Miten arvioida koiran kehonkuntoa?',
      body: 'Normaalipainoisen koiran vyötärölinja on selvästi erottuva ja kylkiluut helposti tunnusteltavissa. Halaa koiraasi ja kokeile! Lisätietoa kehonkunnon arvioimisesta <a href="https://alvarpet.com/koiran-ruokinta/#paino-ja-kehonkunto">täällä.</a>',
    },
    body_condition: {
      '3': 'Hoikka',
      '5': 'Sopiva',
      '7': 'Pulska',
      choose: 'Valitse',
    },
    label: {
      preferred_ingredients: 'Suosikkiraaka-aineet',
      diet_share_dryFood: 'Kuivaruokaa',
      diet_share_other: 'Muuta',
      diet_share_snack: 'Herkkuja',
      diet_share_wetFood: 'Märkäruokaa',
      diet_share_treat: 'Herkkuja\n',
    },
    no_special_needs: 'Ei erityistarpeita',
    remove_error: 'Koiraa ei onnistuttu poistamaan',
    remove_success: 'Koira poistettu',
    no_such_dog: 'Koiraa ei löytynyt',
    no_dogs: 'Ei koiria',
  },
  labels: {
    delivery_pickup_point: 'Noutopiste',
    drop_point: 'Noutopiste',
    email_user_account: 'Email (käyttäjätunnus)',
    password_repeat: 'Salasana uudestaan',
    phone_prefix: 'Maatunnus',
    shipping_address: 'Osoite',
    shipping_city: 'Kaupunki',
    shipping_country: 'Maa',
    shipping_postcode: 'Postinumero',
    starting_date: 'Kokeilupaketin jälkeinen tilaus alkaa',
    contact_info: 'Yhteystiedot',
    interval: 'Toimitusväli',
    invoices: 'Kuitit toimituksista',
    payment_info: 'Maksutiedot',
    shipping_info: 'Toimitustiedot',
    delivery: {
      email_opt_in:
        'Kyllä kiitos! Olen kiinnostunut kokeilualennuksesta, räätälöidyistä ruokintavinkeistä ja koirakuvista.',
    },
    save_dog_info: 'Tallenna tiedot ja lähetä kopio suosituksesta',
    email_opt_in:
      'Kyllä kiitos! Olen kiinnostunut kokeilualennuksesta, räätälöidyistä ruokintavinkeistä ja koirakuvista.',
    current_password: 'Nykyinen salasana',
    new_password: 'Uusi salasana',
    new_password_repeat: 'Uusi salasana uudestaan',
    password: 'Salasana',
    name: 'Nimi',
    first_name: 'Etunimi',
    last_name: 'Sukunimi',
    email: 'Sähköposti',
    country: 'Maa',
    address: 'Osoite',
    phone: 'Puhelinnumero',
    city: 'Kaupunki',
    zip: 'Postinumero',
    delivery_type: 'Toimitustapa',
    home_delivery: 'Toimituskumppani',
    po_box: 'Noutopiste',
    card: {
      card_number: 'Maksukortin numero',
      expiration: 'Voimassa',
      cvn: 'CVN',
      cvc: 'CVC',
    },
    shipping_date: 'Seuraava tilaus pakataan\n',
    next_shipping_date: 'Seuraava toimituspäivä',
    return_product: 'Palauta tuote',
    remove_product: 'Poista tuote',
    delivery_address: 'Toimitusosoite',
    shipping_interval: 'Toimitusväli',
    shipping_size: 'Nappulaa per toimitus',
    billing_method: 'Maksutapa',
    bundle_size: 'Pakkauskoko',
    delay_next_order_modal: {
      interval: 'Toimitusväli\n',
      payment_date: 'Seuraava tilaus:\n',
    },
  },
  nav: {
    home: 'Koti',
    marketing_site: 'Palaa Alvar-etusivulle',
    back_to_marketing_site: 'Palaa Alvar-etusivulle',
    recommendation: {
      confirm_navigation:
        'Hei, haluatko varmasti poistua suosituskoneesta kesken kaiken? 🐾',
    },
    benefits: 'Edut',
    breeders_club: 'Kasvattajille',
    frontpage: 'Etusivu',
    login: 'Kirjaudu',
    logout: 'Kirjaudu ulos',
    my_herd: 'Oma lauma',
    orders: 'Tilaus',
    profile: 'Profiili',
    recommendation_engine: 'Suosituskone',
    recommendation_machine: 'Suosituskone',
    recommendation_machine_confirmation:
      'Olet jo Suosituskoneessa. Haluatko varmasti aloittaa alusta?',
    recommendation_machine_restart: 'Aloita alusta',
    products: 'Tuotteet',
    my_sustainability: 'Oma hiilitassunjälki',
  },
  recommendation: {
    manage_secondary: 'Vaihda / poista',
    feeding_guide: {
      heading: 'Ruokavalio-ohje',
      calorie_overview: {
        heading: 'Energiajakauma',
      },
      lead: 'Eli mitä, miksi ja minkä verran {{dogName}} tarvitsee ravintoa päivässä pysyäkseen terveenä ja onnellisena.',
      macros: {
        heading: 'Ruokavalion makrot',
      },
    },
    diet_plan_intro: {
      heading: '{{dogName}}, tässä suosituksesi!',
      lead: 'Tutustu {{dogName}}-koiran tarpeiden mukaan räätälöityyn ruokavalioon alla. Voit viimeistellä kokeilupaketin sisällön seuraavalla sivulla.',
    },
    consideration_slide: {
      activity: {
        active:
          'Päiväannos mitoitetaan vastaamaan {{dogName}}-koiran aktiivista elämäntyyliä.',
        lazy: 'Päiväannos mitoitetaan vastaamaan {{dogName}}-koiran leppoisaa elämäntyyliä.',
        normal:
          'Päiväannos mitoitetaan vastaamaan {{dogName}}-koiran elämäntyyliä.',
      },
      age: {
        adult: '{{dogName}} saa tarpeidensa mukaan räätälöidyn suosituksen.',
        puppy: 'Suosituksen reseptit on suunniteltu pennun tarpeisiin.',
        senior: 'Suosituksen reseptit on suunniteltu seniorin tarpeisiin.',
      },
      bcs: {
        chubby:
          'Jotta {{dogName}} saa tukea painonhallintaan, suositus on matalaenerginen.',
        ideal:
          'Suosituksessa {{dogName}} saa kaiken tarvitsemansa luonnollisista, korkealaatuisista raaka-aineista.',
        skinny:
          'Jotta {{dogName}} saa lihaa luiden ympärille, suositus on korkeaenerginen.',
      },
      special_need: {
        heartBurn:
          'Närästyksen välttämiseksi suosituksen reseptit ovat matalarasvaisia.',
        neutered:
          'Koska {{dogName}} on leikattu, huomioimme sen reseptivalinnassa ja annoskoossa.',
        pickyEater:
          'Koska {{dogName}} on nirso, suositukseen lisätään hieman ekstraherkullisuutta.',
      },
    },
    diet_plan_heading: '{{dogName}}-koiran räätälöity ruokavalio',
    diet_plan_lead:
      '<b>Tältä näyttää koirasi ravintoarvoiltaan optimaalinen ja ympäristöystävällinen päiväannos.</b> 😍🐾Voit tutustua tuotteisiin ja muokata valintoja alla.',
    thank_you_subtitle: 'Kokeilupakettisi saapuu parissa arkipäivässä.',
    start_email: 'Aloita syöttämällä sähköpostisi',
    restart_confirmation: 'Aloita suosituskone alusta',
    thank_you_title: 'Tervetuloa Alvarin laumaan {{dogName}}!',
    thank_you_lead:
      'Mahtavaa saada sinut ja koirasi mukaan tiedostavien tassusankarien joukkoon!',
    thank_you_notice:
      'Saat pian sähköpostiisi tilausvahvistuksen, josta näet maistiaispakettisi arvioidun toimitusajan.',
    thank_you_share_1:
      'Hemmottele samalla ystäväkoiraa jakamalla tämä kuponki, jolla saa nappulapaketin kylkeen ilmaisen herkun:',
    thank_you_share_2:
      'Ja hyödynnä ihmeessä koodia itsekin seuraavan toimituksen yhteydessä 😎',
    thank_you_add_another:
      'Löytyykö kotoasi toinenkin koirakaveri? Voit tilata maistiaiset enimmillään kolmelle koirallesi.',
    thank_you_add_dog: 'Lisää toinen koira',
    this_is_how_it_works_title: 'Näin homma toimii',
    this_is_how_it_works_slide_0_title: 'Kokeile ilmaiseksi',
    this_is_how_it_works_slide_0_content:
      'Saat kokeiluun noin 2 viikon annokset suositusnappulaa. Maistiaistilaus ei sido sinua mihinkään.',
    this_is_how_it_works_slide_1_title: 'Päätä jatkatko tilausta',
    this_is_how_it_works_slide_1_content:
      'Kokeile, mieti ja päätä tilauksen jatkosta. Muistutamme sinua 2 päivää ennen ensimmäistä veloitusta.',
    this_is_how_it_works_slide_2_title: 'Tutustu asiakassivuusi',
    this_is_how_it_works_slide_2_content:
      'Voit muuttaa tilaustasi tai lopettaa sen asiakassivulla maistelun aikana tai milloin vain sen jälkeen.',
    form: {
      is_breeder: {
        tooltip:
          'Kasvattaja? Liity Alvarin Kasvattajakerhoon! Lähetämme sinulle lisätietoa ja kutsun sähköpostitse (tai voit lukea lisää <a href="https://alvarpet.com/kasvattajat">täältä</a>).',
      },
      is_breeder_label: 'Olen kasvattaja',
      user_vet_consulting:
        'Olen lisäksi kiinnostunut ilmaisesta eläinlääkärin ravintokonsultaatiosta (chat 5–10 min)',
      terms_conditions: 'Hyväksyn',
      terms_conditions_link: ' tilaus ja toimitusehdot',
      demo_order: 'Seuraava',
    },
    order_includes_treats: '+ Herkut!',
    free_order: '1x Ilmaiset maistiaiset',
    optional_subscription_tooltip:
      'Voit keskeyttää jatkuvan tilaamisen koska vain!',
    session_expired: 'Istunto vanhentui. Anna sähköposti uudelleen',
    referral_notification: 'Saat 10 € alennuksen',
    save_payment_info: 'Tallenna maksutiedot',
    show_recommendation: 'Näytä suositus',
    errors: {
      no_recommendation: 'Suositusta ei voitu tehdä',
    },
    pages: {
      email: '{{dogName}}, suosituksesi on valmis!',
      email_loading: 'Luodaan ruokavaliota..',
      dog_info: 'Koiran tiedot',
      activity: 'Aktiivisuus',
      specialNeeds: 'Erityistarpeet',
      recommendation: 'Suositus',
      ingredients_page_title: 'Lempimaut',
      diet_composition: 'Lautasmalli',
      ingredients: {
        disclaimer:
          'Meillä ei vielä ole kaikkiin tarpeisiin sopivia tietyn raaka-aineen reseptejä, mutta pyrimme huomioimaan valinnan suosituksessa.',
      },
    },
    changeProductTitle: 'Kokeile toista reseptiä?',
    changeProductDescription:
      'Jos valitset muun reseptin, annoskoko voi muuttua eivätkä erityistarpeet välttämättä tule huomioiduksi.',
    resetProductButton: 'Vaihda alkuperäiseen suositukseen',
    subscription_ksp: {
      body: {
        home_delivery: 'Nauti säännöllisten toimitusten kätevyydestä. ',
        loyalty_benefits:
          'Hemmottelemme tilaajia tarjouksin ja ilmaisyllätyksin.',
        modify_cancel_any_time:
          'Muokkaa, viivästä tai peruuta tilauksesi milloin vain. ',
        lower_prices: 'Tilaajat saavat <b>10%</b> alennuksen, aina.',
      },
      heading: {
        home_delivery: 'Koiranluukulle, kotiovelle',
        loyalty_benefits: 'Enemmän kuin masurapsutuksia\n',
        modify_cancel_any_time: 'Joustaa tarpeidesi mukaan',
        lower_prices: 'Parhaat hinnat parhaille ystäville',
      },
      section_heading: 'Jatkotilaajan ilot',
    },
    referral_link_text: 'Jaa & saa 10€!',
    thank_you:
      '<p>Olemme niin iloisia, että olet mukana matkallamme kohti pienempää hiilitassunjälkeä. Yhdessä olemme vähentäneet päästöjä jo yli 2 810 632 kg CO2e!🐾</p>\n<p>\n<b>Haluaisitko olla avuksi ja tuoda koirakaverisi mukaan matkalle kohti nollahiilitassunjälkeä?\n</b></p>',
    thank_you_add_another_notice: ' ',
    dog_names_meal_plan_product_count:
      '{{dogName}}-koiran tarpeisiin räätälöimämme ruokintasuunnitelma sisältää {{productCount}} tuotetta: ',
    order_overview: 'Siirry tilaamaan',
    our_recommendation: 'Suosituksemme',
    wet_food_feeds_other: {
      'ALV-711':
        'Kanakulho tuo tervetullutta vaihtelua päivittäisiin aterioihin koiralle, joka ei ole tottunut syömään pelkkää kuivaruokaa, kuten {{dogName}}.\n',
      'ALV-402':
        'Alvarin Maalaiskana tuo tervetullutta vaihtelua päivittäisiin aterioihin koiralle, joka ei ole tottunut syömään pelkkää kuivaruokaa, kuten {{dogName}}. ',
    },
    cart_is_empty: {
      body: 'Klikkaa alta palauttaaksesi alkuperäisen suosituksen.\n',
      header: 'Ostoskorisi on tyhjä!\n',
    },
    change_interval: {
      explainer:
        'Toimitetaan {{weeks}} viikon välein <br/>Muokkaa tai peru milloin vain',
      made_to_fit: 'Aikataulusi mukaan',
    },
    reset_cart_to_recommendation: 'Palauta suositus\n',
    secondary_product: {
      remove: 'Poista',
    },
    treats_feeds_treats: {
      'ALV-202':
        'Kuulimme, että {{dogName}} on herkkusuu. Alvarin Hukkaherkku Nugetit ovat terveellinen ja maittava valinta päivittäiseen koulutukseen ja herkutteluun.',
    },
    wet_food_feeds_wet: {
      'ALV-402':
        'Kotimainen Maalaiskana on maittava ja terveellinen aterian lisuke koiralle, joka on tottunut saamaan säilykeruokaa kuivaruoan rinnalla, kuten {{dogName}}.',
    },
  },
  loading: 'Noudetaan keppejä ja bittejä 🐾',
  product_card: {
    about_product: 'Tuotetiedot',
    more_information: 'Tutustu reseptiin',
  },
  confirm: 'Vahvista',
  action: {
    add: 'Lisää',
    add_to_order: 'Lisää tilaukseen',
    change_dog: 'Vaihda koira',
    remove: 'Poista',
    change_recipe: 'Vaihda resepti',
    go_to_next: 'Seuraava',
    show_more: 'Näytä lisää',
  },
  warning: {
    diet_composition: {
      sum_exceeds_100: 'Ruokavalion summa ylittää 100%',
      sum_less_than_100_percent: 'Ruokavalion summa tulee olla 100%',
    },
  },
  validation_suggestion: {
    diet_composition: {
      reduce_to_match: 'Sovita osuus',
      sum_less_than_100_percent: 'Lisää kuivaruoan määrä sopivaksi',
      sum_more_than_100_percent: 'Vähennä kuivaruoan määrä sopivaksi',
      uses_raw_food: 'Säädä 0%',
      wet_over_recommended: 'Säädä 50%',
    },
  },
  image_alt_text: {
    activity_level_1: 'Laiska koira',
    activity_level_2: 'Keskiaktiivinen koira',
    activity_level_3: 'Hyvin aktiivinen koira',
    body_type_1: 'Hoikka',
    body_type_2: 'Normaali vartaloinen koira',
    body_type_3: 'Pulska koira',
    preferred_ingredient: {
      chicken: 'Kana',
      duck: 'Ankka',
      fish: 'Kala',
      grainFree: 'Ei viljoja',
      insect: 'Hyönteiset',
      noPreference: 'Ei, näytä ensisijainen suositus!',
      plant: 'Kasvikset',
    },
    diet_item: {
      dryFood: 'Kuivaruokaa',
      other: 'Muuta',
      snack: 'Herkkuja',
      wetFood: 'Märkäruokaa',
      treat: 'Herkkuja',
    },
    illustrations: {
      home_delivery: 'kotiinkuljetus',
      loyalty_benefits: 'lahjoja',
      modify_cancel_any_time: ' ',
      lower_prices: 'alennuksia',
    },
  },
  hint: {
    diet_composition: {
      dietShareOther: 'Osuus ruokavaliosta, muu',
    },
  },
  diet_legend: {
    dry: 'Kuivaruoka',
    other: 'Muu / kotitekoinen',
    supplement: 'Lisäravinteet',
    treats: 'Herkut',
    wet: 'Märkäruoka',
  },
  channel: {
    email: 'Ota yhteyttä',
    facebook: 'Alvar Pet',
    instagram: '@alvarpet',
  },
  email: 'Email',
  password: 'Salasana',
  back: 'Takaisin',
  cancel: 'Peruuta',
  change: 'Muuta',
  choose: 'Valitse',
  no_thanks: 'Ei kiitos',
  forgot_password: 'Unohditko salasanan?',
  page_not_found: 'Sivua ei löytynyt',
  yes: 'Kyllä',
  no: 'Ei',
  navigation: {
    go_to_x: 'Siirry',
  },
  login: {
    button: 'Kirjaudu',
    login_in: 'Kirjaudu sisään',
    remember_me: 'Muista minut',
    errors: {
      login_failed: 'Kirjautuminen epäonnistui. Yritä uudelleen',
    },
  },
  langs: {
    fi: 'Suomeksi',
    en: 'In English',
    de: 'Auf Deutsch',
  },
  shipping_countries: {
    se: 'Ruotsi',
    no: 'Norja',
    fi: 'Suomi',
    dk: 'Tanska',
    de: 'Saksa',
  },
  wizard: {
    submit: 'Lähetä',
    next: 'Seuraava',
  },
  units: {
    after_days: 'päivän päästä',
    bottle: 'plo',
    can: 'prk',
    dl: 'dl',
    from: 'alk.',
    grams: 'g',
    hour: 'h',
    kcal: 'kcal',
    kilos: 'kg',
    ml: 'ml',
    pcs: 'kpl',
    price_starting_abbr: 'alk.',
    product_plural: 'tuotetta',
    sample_order: 'kokeilu',
    product_singular: 'tuote',
    tbsp: 'rkl',
    today: 'tänään',
    week: 'viikkoa',
    weeks: 'viikkoa',
    weeks_abbr: 'viikkoa',
    x_pack: '{{x}}-pack',
  },
  links: {
    edit: 'Muokkaa',
    save: 'Tallenna',
    add: 'Lisää',
    read_more: 'Lue lisää',
    show_all: 'Näytä kaikki',
    choose: 'Valitse',
    chosen: 'Valittu',
    view_products: 'Katso tuotteet',
  },
  dates: {
    date_not_valid: 'Ei oikea päivämäärä',
    date_no_future: 'Päivä ei voi olla tulevaisuudessa',
  },
  food: {
    preferred_ingredient: {
      chicken: 'Kana',
      duck: 'Ankka',
      fish: 'Kala',
      grain_free: 'Ei viljoja',
      insect: 'Hyönteiset',
      no_preference: 'Ei, näytä ensisijainen suositus!',
      plant: 'Kasvikset',
    },
    for_special_need: {
      activeDog: 'Aktiivinen koira',
      agingDog: 'Ikääntyvä koira',
      brainWellness: 'Aivojen hyvinvointi',
      delicateStomach: 'Herkkä vatsa',
      grainFree: 'Viljaton',
      greedyEater: 'Taipumus lihoa',
      growingDog: 'Kasvava koira',
      itchyPaws: 'Tassujen kutina',
      jointStiffness: 'Jäykät nivelet',
      loosePoop: 'Löysä kakka',
      neutered: 'Leikattu',
      pickyEater: 'Nirso ruoalle',
      sensitiveSkin: 'Herkkä iho',
      tendencyToGainWeight: 'Taipumus lihoa',
    },
    ksp: {
      title: '{{dogName}}n tarpeet',
      show_all: 'Näytä kaikki huomioidut tarpeet',
    },
    receipt: 'Eläinlääkärien kehittämä resepti',
    origin: 'Valmistettu Tanskassa Pohjoismaisista raaka-aineista',
    for_weight: {
      title: 'Paino {{weight}} kg',
      description: '{{portion}} grammaa nappuloita päivässä',
    },
    nutritions: {
      recommended: {
        title: '{{dogName}}lle optimaaliset ravintoarvot päiväannoksessa',
        lead: '{{dogName}}lle optimaalinen päiväannos on {{dailyGram}} g nappulaa. Se on laskettu {{dogName}}n iän, rodun, aktiivisuustason ja kehotyypin mukaan.',
      },
      show_all: 'Näytä täydellinen ravintosisältö',
    },
    ingredients: {
      title: 'Reseptin keskeiset raaka-aineet',
      show_all: 'Näytä kaikki raaka-aineet',
    },
    carbon_paw_print: {
      title: 'Alvarin matka kohti nollahiilitassunjälkeä',
      slide_0_content: 'Pohjoismaisista, kestävistä raaka-aineista',
      slide_1_content: 'Lähellä asiakasta, tuotettu tuulivoimalla',
      slide_2_content: 'Kotiin toimitettuna ilman kiertoteitä',
      slide_3_content: 'Kaikki materiaalimme ovat kierrätettäviä',
      slide_4_content: 'Jäljelle jäävä hiilitassunjälki kompensoidaan',
    },
    covers_x_weeks: 'riittää {{weeks}} viikoksi',
  },
  nutritions: {
    energy: 'kcal',
    kcal: 'kcal',
    fat: 'Rasvaa',
    fiber: 'Kuitua',
    protein: 'Proteiinia',
  },
  receipt: {
    recommendation: 'Suositusresepti',
    alternatives: 'Muut reseptit',
    confirmation_modal: {
      description: 'Reseptin vaihdon varmistus',
      change_recipe_heading: 'Haluat varmasti vaihtaa reseptiin',
      change_recipe_paragraph:
        'Jos valitset muun kuin suositusreseptin, annoskoko voi muuttua, eivätkä erityistarpeet välttämättä tule huomioiduksi.',
      change_recipe_text: 'Vaihda resepti',
    },
    change_recipe: 'Vaihda resepti',
  },
  addons: {
    title: 'Ethän unohda näitä!',
    add_to_order: 'Lisää',
    edit_order: 'Muokkaa tilausta',
    remove_product: 'Poista',
  },
  feedback: {
    subjects: {
      delivery: 'Toimitus',
      feedback: 'Palaute',
      feeding: 'Ruokinta',
      order: 'Tilaus',
      other: 'Muut',
      payment: 'Maksu',
      products: 'Tuotteet',
      reclamation: 'Reklamaatio',
    },
    labels: {
      message: 'Viestini',
      subject: 'Yhteydenottoni aihe',
    },
  },
  heros: {
    product_recommendation: '{{dogName}}lle suosittelemme',
  },
  order: {
    coupon_confirm: 'Vahvista',
    coupon_label_1: 'Onko sinulla kuponki?',
    coupon_label_2: 'Syötä koodi',
    configure_dog_names_subscription:
      '{{dogName}}-koiran <b>ruokavaliotilaus</b>',
    demo_price: 'Kokeilupaketin hinta',
    dog_names_demo_order: '{{dogName}}-koiran <b>kokeilupaketti</b>',
    sample_total: 'Kokeilupaketti yhteensä',
    subscription_intro:
      'Kokeilupaketti aloittaa jatkuvan tilauksen. Se on kätevä, täynnä etuja ja joustava: voit muokata tai perua toimituksia milloin vain asiakassivullasi. \\nHuomaathan, että alla oleva päivävalinta koskee jatkotilaustasi – kokeilu saapuu joka tapauksessa parissa päivässä.',
    download_receipt: 'Lataa kuitti',
    next_delivery_for_dog_name: '{{dogName}}-koiran seuraava tilaus',
    order_history: 'Tämän tilauksen toimitukset',
    orders: 'Tilaukset',
    you_can_edit_order_until: 'Ehdit muokata tilausta {{untilDate}} saakka',
    next_delivery: 'Seuraava tilaus pakataan\\n',
    next_order: 'Seuraava tilaus',
    no_order: 'Ei tilausta',
    product_discount: 'Seuraavassa tilauksessa tuotteesta {{discount}} alennus',
    no_order_for_1: 'Ei tilausta',
    no_order_for_2: '{{dogName}}-koiralle',
    delivery_time: 'Toimitusaika noin {{days}} arkipäivää',
    delivery_interval: '{{interval_weeks}} viikon välein toimitettuna',
    customer_modification_disclaimer:
      'Voit milloin vain muokata tilauksen sisältöä asiakassivuillasi',
    total: 'Jatkotoimitus yhteensä:',
    table_title: 'Jatkuva tilaus',
    table_title_optional: 'Jatkuva tilaus ({{euro}} / {{interval_weeks}} vk)',
    sample_table_title: 'Maistiaispaketti',
    errors: {
      something_went_wrong:
        'Voi ei! Jotain meni vikaan meidän päässämme, olemme todella pahoillamme!',
      coupon_not_valid: 'Kuponki ei ole oikea tai se on jo käytetty',
    },
    add_treat: 'Saisiko olla myös... ?',
    price_and_then: 'Ja sen jälkeen, jos jatkat tilaajana:',
    lead: 'Ilmaisen kokeilun jälkeen tilaus alkaa valitsemallasi toimitusvälillä. Voit kuitenkin lopettaa tilauksen tai muokata sitä milloin haluat. Jos siis Alvarin nappulat eivät olekaan sinun ja koirasi juttu, ei tilaus ala, eikä maksua tule.',
    lead_no_demo_order:
      'Tilaus alkaa valitsemallasi toimitusvälillä. Voit kuitenkin lopettaa tilauksen tai muokata sitä milloin haluat.',
    notifications: {
      free_shipping:
        'Ilmainen toimitus - Ruokintaohjeet \n Hiilitassunjälki kompensoitu!',
      subscription_discount:
        'Saat seuraavasta toimituksesta {{discount}} € alennuksen!',
    },
    arrives_to_dog: 'koiralle saapuu',
    edit_order: 'Muokkaa tilausta',
    history_product: 'tuote',
    history_product_plural: 'tuotetta',
    pending: 'Odottaa maksua',
    processing: 'Käsittelyssä',
    completed: 'Paketti lähetetty',
    refunded: 'Hyvitetty',
    failed: 'Epäonnistunut',
    returned: 'Palautettu',
    free_sample: 'Ilmainen näyte!',
    subscription_intro_name_grams:
      'Koska {{dogName}} syö {{dailyGrams}} g nappulaa päivässä, tämä toimitusväli sopisi hyvin. Mutta voit mukauttaa sitä milloin vain!',
    on_hold: 'Tilaus jumissa',
    dog_names_single_order: '{{dogName}}-koiran <b>Kertaostos</b>',
    every_x_weeks: 'Joka {{weeks}}. viikko',
    interval: 'Tilausväli',
    per_x_week: '{{weeks}} viikon välein',
    this_order: 'Tämä toimitus',
  },
  coupon_modal: {
    title: 'Kupongin käyttäminen',
    multi_subscriptions_description:
      'Valitse mihin tilaukseen haluat käyttää kupongin',
    coupon_applies_title: 'Kuponki käy seuraaviin tuotteisiin',
    discount_for: 'alennusta',
    product: 'tuotteesta',
    product_plural: 'tuotteesta',
    not_applicaple: {
      description: 'Kuponkia ei voida hyödyntää tässä tilauksessa.',
      hint: 'Lisää ensin tuotteita tilaukseen.',
      hint_multi_subscriptions:
        'Lisää tuotteita tilaukseen ensin tai käytä kuponki toiseen tilaukseen',
    },
    submit: 'Käytä kuponki',
    discount_successfully_applied: 'Alennus lisätty tilauksesi tuotteisiin!',
    no_applicable_product_in_sub:
      'Tilauksesi ei sisällä alennukseen oikeuttavia tuotteita. Voit lisätä tuotteita alta!',
  },
  subscription: {
    coupon_field: {
      disabled_help_text:
        'Kupongit toimivat vain aktiivisille tilauksille, aktivoi siis tilaus ensin ja syötä sitten kuponki. Ei hätää, ehdit hyvin tehdä muutoksia toimitukseen, toimituspäivään ym. – tai vielä perua, jos tulet katumapäälle ☺️',
    },
    welcome_back: 'Tervetuloa takaisin',
    add_treats: 'Lisää herkkuja ja ekstraa',
    cancel: 'Peruuta tilaus',
    delivery_and_payment: 'Toimitus & maksutapa',
    dog_names_subscription: '{{dogName}}-koiran <b>Tilaus</b>',
    next_order_comes_after:
      'Seuraava paketti koiralle {{dogName}} lähtee {{days}} päivän päästä! Voit muokata toimitusta {{untilDate}} asti.',
    sub_total: 'Välisumma',
    summary: {
      delivery: 'Kuljetus',
      x_to_free_delivery: '<b>{{spend}}</b> ilmaiseen kuljetukseen',
    },
    update: 'Päivitä tilaus',
    update_success: 'Muutokset tallennettu',
    price_for_next_delivery: 'Seuraavan toimituksen hinta:',
    will_be_charged: 'Tilauksesi veloitetaan',
    activate: 'Aktivoi tilaus',
    activate_success: 'Tilaus aktivoitiin onnistuneesti!',
    cancel_heading: 'Oletko varma',
    cancel_description:
      'Oletko varma että haluat peruuttaa jatkuvan tilauksen koiralle {{dogName}}?',
    end: 'Lopeta tilaus',
    next_order_comes: 'Seuraava toimitus koiralle {{dogName}} saapuu arviolta',
    added_treats: 'Lisätyt tuotteet',
    choose_which_order_to_fix: 'Valitse mitä toimitusta korjataan',
    choose_pending_payment_action_heading:
      'Mitä tehdään {{dogName}} - koiran toimituksen kanssa',
    pay_now: 'Maksa nyt',
    you_will_get_your_delivery_asap: ', saat toimituksen mahdollisimman pian.',
    move: 'Siirrä',
    delivery_date_later: 'toimituspäivää myöhemmäksi',
    choose_new_delivery_date: 'Valitse uusi toimituspäivä',
    delivery_date_changed: 'Toimituspäivä on vaihdettu',
    you_can_still_make_changes_to_your_next_order:
      'Voit yhä tehdä muutoksia tilauksen sisältöon (lisää herkkuja?)',
    some_recent_order_in_completed_state:
      'Tilauksesi on lähetetty! {{dogName}} ei joudu odottamaan enää kauaa.',
    some_order_needs_payment:
      'Tilauksesi on epäonnistunut. Klikkaa tästä korjataksesi ongelma.',
    some_recent_order_in_processing_state:
      'Tilauksesi on käsittelyssä! {{dogName}} ei joudu odottamaan enää kauaa, toimitusta pakataan.',
    welcome_back_name: 'Tervetuloa takaisin, {{name}}!',
  },
  delivery: {
    demo_shipping_price: 'Toimitusmaksu',
    free: 'Ilmainen',
    date: 'Toimituspäivä',
    products: 'Tuotteet',
    lead: 'Onko ruoka jo loppumassa tai ovatko varastot kenties vielä täynnä? \n Voit aikaistaa tai viivästyttää seuraavaa toimitusta tästä.',
    checking_availability: 'Etsii vaihtoehtoja',
    free_delivery_on_orders_over_spend:
      'Ilmainen kuljetus yli {{spend}} toimituksiin',
    option_not_available: 'Ei saatavilla tälle alueelle',
    regular_price_for_subs_under_x:
      'Ilmainen kuljetus jatkotilauksille, jos tilauksen summa on yli {{minimumSpend}}.',
  },
  payment: {
    method: {
      kco: 'Lasku (Klarna)',
      stripe: 'Luotto- tai pankkikortti (Stripe)',
      credit_card: 'Luottokortti',
      missing: 'Maksutapa puuttuu',
    },
  },
  dog_info_title: 'Koiran tiedot',
  section_title_order: 'Tilaus',
  nutritions_daily_title:
    'Ravintoarvot päiväannoksessa: {{dailyGrams}} g / {{dailyDl}} dl',
  contact_info: {
    email: 'hello@alvarpet.com',
    phone: '+358 50 322 0842',
    take_contact: 'Ota yhteyttä',
    take_contact_lead:
      'Onko sinulla kysyttävää tai palautetta, kenties jopa kehitysidea reseptiin?',
  },
  footer: {
    disclaimer: '© {{year}} ALVAR PET OY. FOOD FOR THE CONSCIOUS PUP.',
    agb: 'Käyttöehdot',
    datenschutz: 'Yksityisyys',
    impressum: 'Impressum',
    privacy_policy: 'Privacy policy',
    widerruf: 'Widerruf',
  },
  rewards: {
    your_saldo: 'Etutililläsi käytettävissä:',
  },
  campaigns: {
    trial_incentive: {
      product_name: 'Herkkubundle',
      product_tooltip:
        'Tuotteet saapuvat muutamassa minuutissa tilauksen aktivoinnin jälkeen',
      subscription_activate_success:
        'Tilaus aktivoitiin onnistuneesti! Herku ilmaantuvat tilauksellesi muutaman minuutin kuluttua!',
    },
    wetfood_promo: {
      title: 'Saisko olla myös kotimaista märkäruokaamme? Valitse:',
      disclaimer: 'Ilmaisnäyte, {{price}}',
    },
    tags: {
      new: 'Uutta Alvarilla',
      free: 'Tarjous 0€!',
      limited_time: 'Vain rajoitetun ajan',
      'puppybox-dk': {
        upgrade_for_price: 'Nappaa 100 DKK lisähintaan',
      },
      'puppybox-se': {
        upgrade_for_price: 'Nappaa 100 SEK lisähintaan',
      },
      puppybox: {
        upgrade_for_price: 'Nappaa 10€ lisähintaan',
      },
    },
    chews_promo: {
      disclaimer:
        'Kokeile kotimaisia puruluitamme! Kaikki purtavat tuotteemme on tehty pohjoisen metsien ja järvien luonnollisista, villeistä raaka-aineista.',
      title: 'Pureskeltavaa loppuvuoteen',
    },
    free_fish_oil: {
      disclaimer: '',
      title: '',
    },
  },
  reward_block: {
    title: 'Jaa & ansaitse alennuksia',
    step_1_title: 'Jaa koodisi',
    step_1_description:
      'Ystäväsi saa {{discount}} edun jatkotilaukselleen, kun he tilaavat linkkisi kautta.',
    step_2_title: 'Ansaitse alennus',
    step_2_description:
      'Saat itsekin {{discount}} edun jatkotilauksellesi, kun ystäväsi tekee tilauksen linkin kautta.\n\nVoit kutsua enintään 20 ystävää linkkisi kautta vuodessa. ',
    share_button: 'Kopioi jakolinkki',
  },
  cancel_subscription: {
    allergic_reaction: {
      body_1:
        'Ruoka-allergian aiheuttaa yleensä jokin ruoan proteiini. Valitessa ruokaa herkälle koirallesi, kannattaa harkita innovatiivisia proteiininlähteitä, joita koirasi ei ole syönyt aiemmin. Mites hyönteisproteiini?',
      heading: 'Ei hätää, voimme korjata tämän! ',
    },
    condolence: {
      heading: 'Olemme niin pahoillamme 💔',
      body: '"<p>Ainoa huono puoli koirissa on niiden liian lyhyt elämä. Lopetimme tilauksesi. Syvimmät osanottomme!\n</p>\n<p>- ystäväsi Alvarilla"',
    },
    consultation_link_didn_t_open:
      "Jos varauskalenteri ei auennut, klikkaa <a href='https://calendly.com/alvar-henna/ruokintaneuvonta'>tästä.</a>",
    email_consultation_success: 'Kiitos',
    i_want_still_cancel: 'Haluan yhä perua. ',
    not_tasty: 'Ei maistunut koiralleni',
    sorry_but_why: {
      body: 'Harmi kuulla, että haluat perua {{dogName}}-koiran tilauksen. Kerrothan meille syyn, jotta osaamme kehittää Alvaria!',
      heading: 'EEEIIII – mutta miksi? ',
    },
    thanks_for_your_trust: 'Kiitos luottamuksestasi',
    vet_advised_change: {
      body: 'Meillä saattaa olla valikoimassamme vastaava vaihtoehto koirallesi. Voimmeko olla avuksi?',
      heading: 'Toivottavasti {{dogName}} löytää sopivan ruoan!',
    },
    we_will_send_your_consultation:
      '<p>Lähetämme sinulle suosituksen pian!</p><p>Voit vaihtaa seuraavan toimituspäivän myöhemmäksi, jos haluat odottaa neuvojen tuloksia.</p>',
    worse_poop: {
      body: 'Joskus ruokavalion muutos, varsinkin jos ruoan kuitukoostumus muuttuu, voi aiheuttaa väliaikaisia kakan laadun muutoksia. Maltti on valttia – tai voit kokeilla valikoimastamme erityylistä reseptiä, joka saattaa sopia koirallesi paremmin.',
      heading: 'Ei hätää, voimme korjata tämän! ',
    },
    you_can_always_make_changes:
      'Voit milloin vain tehdä muutoksia tilaukseesi. Kerrothan, jos voimme auttaa!',
    recipe_not_suitable: 'Resepti ei sopinut koiralleni',
    i_ll_change_my_recipe: 'Vaihdan reseptiä',
    i_want_to_cancel_subscription: 'haluan lopettaa tilauksen',
    would_you_like_to_try_another_recipe:
      'Haluaisitko kokeilla jotain toista Alvarin reseptiä?',
    would_you_like_to_deley_next_delivery_date_instead:
      'Haluatko enemmin myöhäistää seuraavaa toimituspäivää?',
    choose_most_appropriate_option_and_tell_us_more_below_if_you_like:
      'Valitse osuvin vaihtoehto ja kerro halutessasi lisää alla.',
    what_in_subscription_model_is_that_you_dont_like:
      'Mikä jatkuvassa tilausmallissa ei ole mieleesi?',
    did_you_know_that_by_ordering_a_larger_batch_at_once_you_can_save_up_to_x_off_the_price:
      'Tiesitkö, että tilaamalla kerralla suuremman erän, voit säästää hinnassa jopa 30%',
    what_kind_of_food_related_issues_you_experienced:
      'Minkälaisia ruokaan liittyviä ongelmia koitte?',
    how_could_we_improve:
      'Olemme pahoillamme. Mitä osa-aluetta voisimme parantaa?',
    can_we_ask_why: 'Saammeko kysyä syytä, palautteesi on meillä arvokasta!',
    worse_quality_of_poop: 'Kakan koostumus heikkeni',
    increased_amount_of_poop: 'Kakan määrä kasvoi',
    kibble_size_not_suitable: 'Väärän kokoinen nappula',
    too_hard_kibbles: 'Nappulan kovuus',
    itching: 'Ilmeni kutinaa',
    not_happy_with_ingredients: 'Ainesosat eivät miellyttäneet',
    too_much_food: 'Minulla on liikaa ruokaa',
    smaller_delivery_size: 'Pienennetään toimituskokoa?',
    delay_next_delivery: 'Viivästetään toimitusta?',
    no_cancel_subscription: 'Ei, lopetetaan tilaus',
    dont_like_subscription_based_model: 'En pitä jatkuvasta tilausmallista',
    service_not_good: 'Palvelu ei vastannut odotuksiani',
    price_too_high: 'Hinta liian korkea',
    other_reason: 'Muu syy',
    managing_subscription_was_difficult: 'Tilauksen hallinnointi oli vaikeaa',
    delivery_has_problems: 'Toimituksessa oli ongelmia',
    problems_with_payment: 'Maksussa oli ongelmia',
    couldnt_find_what_i_was_looking_for: 'En löytänyt etsimääni',
    food_didnt_taste_good: 'Ruoka ei maistunut hyvältä',
    i_want_think_carefully: 'Haluan miettiä rauhassa',
    i_have_no_dog_anymore: 'Minulla ei ole koiraa enää',
    i_didnt_order_to_my_own_dog: 'En tilannut omalle koiralleni',
    i_combine_subscriptions: 'Yhdistän koirieni tilaukset',
    something_else: 'Jotain ihan muuta',
    i_want_to_disable_between_deliveries:
      'Haluan varmuuden vuoksi laittaa tilauksen pois päältä toimitusten välillä',
    subscription_model_wont_fit_to_my_needs:
      'Jatkuva tilausmalli ei sovi tarpeisiini',
    i_ll_activate_subscription_when_i_need_more_food:
      'Aktivoin tilaukseni kun tarvitsen lisää ruokaa',
    i_want_test_food_in_peace: 'Haluan testailla ruoan sopivuutta rauhassa',
    sorry_to_hear_you_want_to_cancel:
      'Harmi kuulla, että haluat lopettaa tilauksen',
    lets_fix_this: 'Korjataan asia',
    if_you_cancel_you_will_lose_your_benefits:
      'Jos lopetat tilauksen, menetät Alvaristi-etusi!',
    cancelled: 'Tilauksesi on peruttu',
    you_are_always_welcome_back:
      'Muistathan, että olette aina tervetulleita takaisin!',
    change_delivery_date: 'Vaihda toimituspäivä',
    great: 'Mainiota!',
    lets_change_delivery_size: 'Vaihdetaan tilauskokoa',
    we_have_couple_of_solutions_to_fix_your_worries:
      'Tähän pulmaan meillä on ässä hihassa. Mitä jos: ',
    so_that_you_dont_need_to_always_have_too_many_bags:
      'Näin nurkkiin ei kerry ylimääräistä',
    so_that_you_can_feed_the_current_kibbles_away_before_receiving_new_ones:
      'Näin ehditte käyttämään loppuun aiemmat varastot.',
    by_cancelling_your_subscription_you_will_loose_your_benefits:
      'Huomaathan, että jos lopetat tilauksen, menetät Alvaristi-etusi.',
    cancel_subscription: 'Lopeta tilaus',
    change_delivery_size: 'Muokkaa toimituskokoa',
    next_shipping_date: 'Seuraava toimituspäivä',
    changes_saved: 'Muutokset tallennettu',
    total_price: 'Hinta yhteensä',
    consultation_awaits: 'Varaa sinulle sopiva neuvonta-aika',
    need_more_time: 'Tarvitsen lisää aikaa kokeiluun',
    need_more_time_lead:
      'Kokeilu kesken ettekä ole vielä päättäneet? Siirretään seuraavaa toimitusta, niin saatte lisää aikaa. ',
    request_failed: {
      text: 'Ole hyvä ja yritä myöhemmin uudelleen, tai ota yhteyttä asiakastukeemme!',
      title: 'Tapahtui virhe.',
    },
    unhappy_with_service: 'En ole tyytyväinen palveluun',
  },
  page_title: {
    '404': '404 - Sivua ei löytynyt',
    wrapped: '2022 Paketissa',
    benefits: {
      coupon_page: 'Kupongit',
      frontpage: 'Alvaristi-etusi',
      referral_page: 'Suositukset',
      rewards_page: 'Edut',
    },
    breeders: {
      frontpage: 'Alvarin Kasvattajakerho',
    },
    frontpage: 'Asiakassivu',
    recommendation_machine: 'Suosituskone',
    order: 'Tilaus',
    profile: {
      edit_user: 'Muokkaa käyttäjätietoja',
      edit_shipping: 'Toimitusosoite',
      edit_payment: 'Muokkaa maksutietoja',
      frontpage: 'Profiili',
    },
    rewards: 'Lahjakortit ja alennukset',
    sustainability: 'Oma hiilitassunjälki',
    orders: {
      frontpage: 'Tilaukset',
      edit: 'Muokkaa tilausta',
    },
    my_herd: {
      frontpage: 'Oma lauma',
      edit: 'Muokkaa tietoja',
    },
  },
  trial_promo: {
    tag: 'Erikoistarjous',
    description:
      'KAIKKI extrat, ilmaiseksi, juhlistaaksemme ensimmäistä tilaustasi!',
    link_text: 'Aktivoi nyt',
    splash_text: 'Etu%!',
  },
  account_missing_info: 'Täydennä puuttuvia tietoja',
  cookie: {
    notice:
      'ALVAR <3 COOKIES. Käytämme evästeitä Alvarin käyttökokemuksen kehittämiseen.',
    read_more: 'Lue lisää',
    ok: 'OK',
  },
  copied_to_clipboard: 'Kopioitu leikepöydälle',
  accessibility: {
    open_options: 'Avaa valinnat',
    close_menu: 'Sulje valikko',
    open_menu: 'Avaa valikko',
  },
  meta_description: {
    recommendation:
      'Räätälöi koirasi tarpeisiin sovitettu ruokavalio ja tilaa 2 kg nappulamaistiaiset kotiovelle! Kokeilupaketti alk. {{demoPrice}}.',
    frontpage:
      'Tervetuloa Alvarin asiakassivuille! Kirjaudu sisään ja hallinnoi tilauksesi sisältöä, ajankohtaa tai toimitusväliä.',
  },
  product: {
    clearance_nuggets: {
      long: 'Hävikkidiili: Nuggetti',
      short: 'Nuggetti',
    },
    clearance_fish_oil: {
      short: 'Hävikkidiili: Villikalaöljy',
      long: 'Hävikkidiili: Alvarin Villikalaöljy 100ml',
    },
    info_text: 'Tuotetiedot',
    puppy_box: 'Alvarin pentuuspakkaus',
    'zero-emission': {
      accessory: 'Tarvikkeet',
      dryFood: 'Alvarin Nollapäästönappula',
      kibble: 'Alvarin Nollapäästönappula',
      puppyBox: 'Pennulle',
      sauce: 'Lisäravinne',
      snack: 'Hukkaherkku',
      supplement: 'Lisäravinne',
      wetFood: 'Lisuke',
      chew: 'Alvarin Hukkapätkä',
      toy: 'Alvarin Uusiolelu',
      treat: 'Hukkaherkku',
    },
    german_fish_and_chips: {
      long: 'Hävikkidiilit: Fisu & Sipsi',
      short: 'Fisu & Sipsi',
    },
    steady_sample: 'Vakaa',
    root_sample: 'Juuri',
    bug_sample: 'Ötökkä',
    serene_sample: 'Tyyni',
    slender_sample: 'Solakka',
    zippy_sample: 'Pirteä',
    out_of_stock: ' Tilapäisesti ei saatavilla',
    view: 'Selaa',
  },
  puppy_box: {
    item: {
      tote_bag: 'Kangaskassi',
      description:
        'Pentuuspakkaus on valikoima tuotteitamme, joiden avulla hiilitassunjälki pysyy pienenä ensiaskelista lähtien.',
    },
    description:
      'Pentuuspakkaus on valikoima tuotteitamme, joiden avulla hiilitassunjälki pysyy pienenä ensiaskelista lähtien.',
  },
  benefits: {
    rewards: {
      claim: 'Käytä etu',
      claimed: 'Etu hyödynnetty',
      order: 'Tilaus',
      order_0: 'Tervetuliaisalennus',
      reward_information:
        'Alvarin uskolliset ystävät kokeilevat tuotteitamme ilmaiseksi. 💚\\n\\nPeräkkäiset toimitukset kerryttävät sinulle Alvaristi-etuja hyödynnettäväksi. Valitsemasi tuote lisätään jatkotilauksellesi toistaiseksi. Etu otetaan huomioon seuraavalla tilauksellasi.',
      reward_terms: 'Etujen ehdot',
      rewards_for_dog_name: '{{dogName}}-koiran Alvaristi-edut',
      timeline_title: 'Etupolun askelmat',
      you_have_x_unclaimed_rewards: 'Sinulla on {{count}} etua lunastamatta',
      coming_up: 'Tulossa',
      youre_here: 'Olet tässä:',
      next: 'Tulossa',
      previous: 'Edellinen',
      activation_callout:
        'Jotta voit hyödyntää Alvaristi-etuja, tarvitset aktiivisen tilauksen.',
      new_reward_in: 'Uusi etu tulossa',
      new_reward_in_orders: 'tilauksen päästä',
      new_reward_in_order: 'tilauksen päästä',
    },
    page_header: 'Alvaristi etuja',
    navigation_option: {
      coupon: 'Kupongit',
      referral: 'Suosittelu',
      rewards: 'Etupolku',
    },
    coupon: {
      explainer:
        'Kuponki lisätään seuraavaan tilaukseesi. Lähetämme uskollisille ystäville aika ajoin yllätyskuponkeja sähköpostitse, pysy tarkkana!\n\nHuom. mikäli lisäät kupongin ja poistat tuotteen jälkeenpäin tilaukseltasi, et voi hyödyntää kuponkia uudelleen.',
      header: 'Syötä kuponki:',
    },
    referral: {
      activation_callout:
        'Hei siellä! 😍 Jotta voit hyödyntää etuja, tarvitset aktiivisen tilauksen.',
    },
  },
  user: {
    we_miss_u: 'Meillä on ikävä sinua',
    we_miss_u_lead:
      'Olisiko aika uuden nappulatilauksen? Jos jokin askarruttaa, ethän epäröi ottaa yhteyttä asiakastukeen!',
    start_by_adding_dog: 'Aloita lisäämällä koira',
  },
  product_category: {
    accessory: 'Tarvikkeet',
    chew: 'Puruluut',
    dryFood: 'Kuivaruoat',
    toy: 'Lelut',
    long: {
      dryFood: 'Alvarin Nollapäästönappula',
      chew: 'Alvarin puruluut',
      accessory: 'Alvarin tarvikkeet',
      supplement: 'Alvarin öljyt',
      wetFood: 'Alvarin märkäruoat',
      treat: 'Alvarin herkut\n',
    },
    all: 'Kaikki',
    sauce: 'Lisukkeet',
    snack: 'Herkut',
    supplement: 'Öljyt',
    wetFood: 'Märkäruoat',
  },
  unit: {
    kg_co2e: 'kg CO2e',
    '%': '%',
  },
  ally_text: {
    change_subscription_configuration: 'Muokkaa valintoja',
    edit_address: 'Muokkaa osoitetta',
    edit_subscription: 'muokkaa tilauksesi ',
    decrease_quantity: 'vähemmän',
    increase_quantity: 'enemmän',
  },
  recommended_option: 'Suositus',
  subscription_configuration: {
    additional_products: 'Muut tuotteet',
    select_next_order: {
      heading: 'Milloin haluat seuraavan toimituksen?',
    },
    x_kg_y_weeks: '{{kg}} kg <br/>{{weeks}} viikon välein',
    explainer:
      '<div class="drop-letter">💡 Sujuvan arjen varmistamiseksi olemme luoneet alle {{dogName}}-koiralle sopivan jatkuvan tilauksen, joka varmistaa että tulevat toimitukset saapuvat ajallaan. </div> Jatkuva tilaus perustuu kokeilupaketin tuotteille, mutta sitä voi muuttaa tai perua milloin vain.',
  },
  checkout_pre_footer: {
    if_you_continue: 'Jos jatkat tilaajana',
    join_other_pupstomers:
      'Liity monituhattassuiseen onnellisten ja terveiden alvaristien joukkoon.',
    summary: 'Yhteenveto',
    tasting_package: 'Kokeilupaketti',
    subscription_summary: 'Tilauksen yhteenveto',
    this_order: 'Tämä toimitus',
  },
  discount_overview: {
    discount_applied: 'Alennus lisätty',
    discount_applies_to_cheapest: '\nAlennus edullisimmasta tuotteesta.',
  },
  breeders: {
    box_content: {},
  },
  link: {
    order_history: 'Tilaushistoria',
  },
  alt_text: {
    complexes: {
      digestion: 'Digestion+',
      joint_support: 'Joints & Mobility+',
      'mother_&_puppy': 'Mother & Puppy+',
      puppy: 'Puppy+',
      senior: 'Senior+',
      sensitivity: 'Sensitivity+',
      'skin_&_coat': 'Skin & Coat+',
      weight_control: 'Weight Control+',
    },
  },
  customer_service: {
    consultation: {
      body: 'Epävarma koirasi ruokavaliosta? Eläinlääkärimme Henna auttaa!',
      link_text: 'Varaa neuvonta',
    },
    contact: {
      body: 'Jos tarvitset apua, {{name}} on täällä sinua varten! Laita viestiä:',
      link_text: 'hello@alvarpet.com\n',
    },
    faq: {
      body: 'Jos sinulla on kysyttävää toimituksista ja tuotteista, kurkkaa',
      link_text: 'Usein kysyttyä -osiomme',
    },
    heading: 'Asiakastuki ',
    trustpilot: {
      body: 'Oletteko sinä ja koirasi tyytyväisiä Alvariin?',
      link_text: 'Kirjoita Trustpilot-arvio',
    },
  },
  product_option: {
    out_of_stock: ' Ei saatavilla',
  },
  validation: {
    invalid_shipping_country: 'Virheellinen maa',
  },
  subscription_card: {
    action: {
      activate: 'Aktivoi',
      add_coupon: 'Kuponki',
      delay_next_order: 'Siirrä seuraavaa toimitusta',
      pay_now: 'Maksa nyt',
      resolve_issue: 'Resolve issue',
    },
    status: {
      on_the_way: 'Toimitus on matkalla ja saapuu 1–3 päivässä',
      cancelled: 'Tilaus on peruttu\n',
      next_delivery_scheduled_for: 'Seuraava toimitus: {{date}}',
      on_hold: {
        needs_payment:
          'Maksu epäonnistui – virhe korjattava ennen tilauksen käsittelyä',
        something_wrong: 'Tilauksessa pulmia: täytäthän puuttuvat tiedot! ',
      },
    },
    notification: {
      you_unlocked_a_reward: 'Uusi alvaristietu saatavilla tälle tilaukselle!',
    },
  },
  subscription_list: {
    add_another_dog: 'Lisää toinen koira\n',
    heading: {
      order_status: 'Toimituksen tila',
    },
  },
  change_dog: {
    select_dog: 'Valitse koira',
  },
  campaign_hero: {
    product_launch: {
      deer_treat_launch: {
        body: 'Uutuusherkku kuivatusta villipeurasta. 🦌 Ei ainoastaan kuolattavan hyvää, vaan myös luonnollista terveellistä. Tehty vastuullisesta ruotsalaisesta raaka-aineesta: 100% villipeuraa eikä yhtään mitään muuta.\n',
        title: 'Ylpeänä esittelemme Alvarin Hukkapala Peura\n',
      },
      bug_treat: {
        body: 'Namit on valmistettu kotimaassa kasvatetuista hyönteisistä. 😍',
        title: 'Maistuisiko -20% Ötökkäherkuista?',
      },
      dental: {
        body: 'Alvarin ja Kivuttoman yhdistymisen kunniaksi uutta Alvaristeille. 🦷 Valitse 3 vaihtoehdosta tai testaa koko trio! ',
        title: 'Lisää hammashoitotuotteet tilauksellesi',
      },
    },
  },
  modal: {
    delay_next_order: {
      heading: 'Siirrä seuraavaa toimitusta\n',
    },
  },
  cancellation_form: {
    not_suitable: {
      try_sensitivity_products: {
        include_kibble: {
          tooltip: {
            'ALV-108-FI':
              'Vakaa: viljaton resepti villikalaproteiinilla keskikokoisessa nappulakoossa.\n',
            'ALV-106':
              'Herkkä: viljaton resepti ankkaproteiinilla keskikokoisessa nappulakoossa.',
            'ALV-107':
              'Pirteä: viljaton resepti siipikarjaproteiinilla ekstrapienellä nappulakoolla.',
            'ALV-108':
              'Vakaa: viljaton resepti villikalaproteiinilla keskikokoisessa nappulakoossa.',
            'ALV-110':
              'Ötökkä: viljaton resepti hyönteisproteiinilla keskikokoisessa nappulakoossa.',
          },
        },
      },
    },
    not_tasty: {
      try_picky_products: {
        include_kibble: {
          tooltip: {
            'ALV-107':
              'Pirteä on pienten nirsoilijoiden suosikki. Viljaton resepti siipikarjaproteiinilla, täynnä makua pienellä nappulakoolla. ',
            'ALV-108':
              'Vakaa on nirsojen koirien suosikki. Viljaton resepti villikalaproteiinilla, keskikokoisessa nappulakoossa. ',
          },
        },
      },
    },
  },
  subscription_switcher: {
    one_time_order: {
      heading: {
        save_on_orders: 'Säästä kaikista tilauksista',
      },
    },
    subscription: {
      body: 'Kokeilupaketti on Alvarin jatkotilauksen aloitustarjous. Haluatko tuotteet mieluummin kertaostoksena alennuksetta? ',
    },
  },
  cart: {
    delivery_price: 'Kuljetus ',
    total: 'Yhteensä\n',
  },
  shipping_price: {
    calculated_at_checkout: 'lasketaan kassalla',
    free: 'Ilmainen\n',
    free_over_x: 'Ilmainen yli {{minimumSpend}} toimituksiin\n',
  },
  opt_in_modal: {
    body: 'Haluan saada koiralleni räätälöityjä suosituksia.',
    close: 'Ei kiitos',
    heading: 'Ruokintasuosituksia sähköpostiin?',
    submit: 'Kyllä kiitos!',
  },
  analytical_constituents: 'Ravintoaineet',
  ash: 'Tuhka',
  close: 'Sulje',
  fat: 'Rasva',
  fiber: 'Kuitu',
  protein: 'Proteiinia',
  subscription_status: {
    active: 'Aktiivinen',
  },
  calcium: 'Kalsium',
  magnesium: 'Magnesium',
  moisture: 'Kosteus',
  omega_3: 'Omega-3\n',
  omega_6: 'Omega-6',
  phosphorus: 'Fosfori',
  potassium: 'Kalium',
  skip_order: {
    body: 'Tilaus käsitellään <b>{{nextOrder}}<b/> ja saapuu siitä 1–3 päivän kuluttua.',
    heading: 'Oletko varma?',
  },
  sodium: 'Natrium',
};
