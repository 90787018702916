import { Coupon } from '../../types';
import { reportError } from '../bugsnag';

interface CouponProps {
  token: string;
  coupon_code: string;
}

export const getCoupon = async <T extends boolean>({
  token,
  coupon_code,
}: CouponProps): Promise<Coupon | { error: string }> => {
  try {
    const couponResponse = await fetch(
      `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/coupon`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ coupon_code }),
      },
    );

    if (couponResponse?.ok) {
      const data = await couponResponse.json();
      return data;
    }

    return { error: couponResponse.statusText };
  } catch (error) {
    reportError(error);
    const errorMessage =
      typeof error === 'string'
        ? error
        : typeof (error as { message?: string })?.message === 'string'
        ? (error as { message: string }).message
        : '';

    return {
      error: errorMessage,
    };
  }
};

export const getUnauthorizedCoupon = async (
  code: string,
): Promise<Coupon | { error: string }> => {
  try {
    const couponResponse = await fetch(
      `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/coupon/${code}`,
    );
    const data = await couponResponse.json();

    if (couponResponse?.ok) {
      return data;
    }

    return { error: data.message };
  } catch (error) {
    reportError(error);
    return { error: (error as Error).message || '' };
  }
};

export const checkAlvarref = async ({
  alvarref,
}: {
  alvarref: string;
}): Promise<boolean> => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/alvarref/${alvarref}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res.ok;
  } catch (error) {
    reportError(error);
    return false;
  }
};
