export default {
  addons: {
    add_to_order: 'Tilføj til din ordre',
    title: 'Glem ikke disse!',
  },
  button_text: {
    browse_all_products: 'Se alle produkter',
    selected: 'Valgt',
    select_product: 'Vælg',
    faq: 'Ofte stillede spørgsmål',
    cancel: 'Annuller',
    continue: 'Fortsæt',
    product_details: 'Detaljer',
    sign_in: 'Log ind',
    sign_up: 'Opret konto',
    view_alternatives: 'Vis alternativer',
    add_to_subscription: 'Tilføj til abonnement',
    updating_subscription: 'Opdaterer abonnement',
    click_to_add_coupon: 'Tilføj rabatkode',
    configure: 'Rediger',
    switch_to_one_time_order: 'skifte til engangsbestilling',
    change_delivery_schedule: 'Ændr leveringsplan',
    order_overview: 'Bestillingsoversigt',
    skip_next_order: 'Spring næste levering over',
    edit_subscription: 'Rediger abonnement',
    read_more: 'Læs mere',
    confirm: 'Bekræft',
    view_change_product: 'Mere info / ændre produkt',
  },
  campaign: {
    share_referral: {
      copy: 'Kopier dit link og del det',
      success: 'Kopieret! Klar til at dele',
    },
    action: {
      add_to_order: 'Tilføj til din ordre',
    },
  },
  campaigns: {
    referral: {
      body: 'Spred budskabet! Del dit personlige henvisningslink og både du og din hundeven vil få 100 kr! Læs hvordan det virker <a href=\\"/benefits/referral\\">her</a>',
      title: 'FÅ 100 KR. GIV 100 KR 💕',
    },
    valentine: {
      disclaimer:
        'Til ære for Valentinsdag får du en gratis pose af vores yndlingsgodbidder med i din prøvepakke.',
      title: '❤️ En gratis Valentinssnack!',
    },
    tags: {
      limited_time: 'Kun i en begrænset periode',
      new: 'Nyheder fra Alvar',
      'puppybox-dk': {
        upgrade_for_price: 'Opgrader for 100 kr',
      },
      'puppybox-se': {
        upgrade_for_price: 'Opgrader for 100 SEK',
      },
      puppybox: {
        upgrade_for_price: 'Opgrader for 10€',
      },
    },
    chews_promo: {
      disclaimer:
        'Prøv vores nordiske tyggeben! Alle vores ben er 100% naturlige og baseret på vildt fra Nordens skove og søer.',
      title: 'Noget at tygge på?',
    },
  },
  cancel_subscription: {
    allergic_reaction: {
      body_1:
        'Fødevareallergi er typisk forårsaget af et specifikt protein i foderet. Når du skal vælge foder til din sensitive hund, er det en god idé at kigge efter foder med innovative proteinkilder, som hunden ikke har spist før – hvad med insektprotein?',
      heading: 'Bare rolig, vi kan løse dette!',
    },
    condolence: {
      heading: 'Det er vi så kede af at høre. 💔',
      body: '"<p>Det eneste dårlige ved hunde er, at deres liv er alt for kort. Vi har afmeldt dit abonnement, og sender vores varmeste tanker til dig!\n</p>\n<p>- dine venner hos Alvar"',
    },
    consultation_link_didn_t_open:
      "Hvis kalenderen ikke åbnede, klik <a href='https://calendly.com/alvar-henna/ruokintaneuvonta'>her.</a>\\n",
    email_consultation_success: 'Tak',
    i_want_still_cancel: 'Jeg vil stadig gerne afmelde',
    not_tasty: 'Min hund kan ikke lide foderet',
    sorry_but_why: {
      body: 'Vi er kede af at høre, at du ønsker at opsige {{dogName}}s abonnement. Fortæl os venligst årsagen, så vi kan blive ved med at forbedre Alvar!',
      heading: 'Åh nej – men hvorfor?',
    },
    thanks_for_your_trust: 'Tak for din tillid',
    vet_advised_change: {
      body: 'Vi har måske en lignende løsning. Kunne du være interesseret i vores hjælp?',
      heading: 'Vi håber du finder en god løsning for {{dogName}}',
    },
    we_will_send_your_consultation:
      '<p>Vi vil kontakte dig snarest muligt!</p><p>Husk, at du kan udskyde din næste levering, hvis du ønsker at vente på resultatet af konsultationen.</p>',
    worse_poop: {
      body: 'Nogle gange kan ændring af hundens foder, især hvis den nye mad har en anden fibersammensætning, føre til midlertidige ændringer i afføringen, dette går som regel væk. Tålmodighed er nøglen her - ellers har vi også andre foderblandinger, som måske passer bedre til din hund.',
      heading: 'Bare rolig, vi kan løse dette!',
    },
    you_can_always_make_changes:
      'Du kan til enhver tid foretage ændringer i din ordre. Fortæl os, hvis der er noget, du vil have hjælp til!',
    consultation_awaits: 'Book en aftale!',
    need_more_time: 'Jeg har brug for mere tid til at afprøve',
    need_more_time_lead:
      'Stadig igang med at afprøve foderet? Lad os rykke den næste leveringsdato, så du har mere tid til at beslutte dig.\n',
    request_failed: {
      text: 'Venligst prøv igen senere eller kontakt vores kundeservice!',
      title: 'Der opstod en fejl.',
    },
    unhappy_with_service: 'Jeg er utilfreds med servicen',
  },
  delivery_prices: {
    price_over_x_money: '{{discounted}} for ordrer over {{spend}}',
  },
  discount: {
    applies_to_all: '{{discount}} rabat på alle produkter',
    applies_to_categories: '{{discount}} rabat på udvalgte {{categories}}',
    product_category: {
      '2262': 'tørfodder',
      '3211': '',
      '4567': 'toppers',
      '4933': '',
      '5011': 'tyggeben',
      '5013': 'tyggeben',
      '5015': 'hundeposer',
      '5021': 'godbidder',
      '5023': 'godbidder',
      '5029': 'tørfodder',
      '5031': 'tørfodder',
      '5035': 'olier',
      '5037': 'olier',
      '5041': 'toppers',
      '5043': 'toppers',
      '5183': 'tilbehør',
      '6540': 'finsk tørfodder',
      '6705': 'tørfodder',
      '7029': '',
      '7671': 'toppers',
      '7677': 'godbidder',
      '7681': 'tyggeben',
      '7687': 'tilbehør',
      '7693': 'tørfodder',
      '7697': 'kosttilskud',
      '7814': 'tilbehør',
      '7818': 'kosttilskud',
      '8262': 'kombitilbud',
      '8264': 'kombitilbud',
      '8266': 'kombitilbud',
      '8349': '',
      '8383': 'legetøj',
      '8386': 'legetøj',
      '8388': 'legetøj',
      '8747': '',
      '8749': '',
      wetFood: 'toppers',
      generic_categories: 'produkter',
      snack: 'godbidder',
    },
    applies_to_category: 'Rabatten gælder for {{category}}',
    applies_to_multiple_product_names:
      '{{discount}} rabat på {{productList}}. Rabatten gælder op til {{productLimit}} produkter i din næste levering.',
  },
  discounts: {
    dog_names_discounts: 'Tilføjede og aktive rabatter',
  },
  free_shipping: {
    included: '<b>GRATIS LEVERING INKLUDERET</b>',
    over_x_money: '<b>GRATIS LEVERING</b> på ordrer over {{money}}',
    available:
      '<b>Gratis levering er tilgængelig, gå til din profil for at ændre forsendelsesmetode </b>',
  },
  frontpage_notification: {
    shipping_price_changes: {},
  },
  label: {
    choose_product: 'Vælg produkt',
    delivery: 'Levering',
    price: 'Pris',
    product_category: 'Kategori',
    quantity: 'Antal',
    size: 'Størrelse',
    variant: 'Model',
  },
  my_pack: {
    get_recommendation: 'Se anbefaling og abonner',
    recommended_diet_plan: {
      explanation:
        'Nærende og bæredygtigt. 💚 Husk at opdatere eventuelle ændringer i {{dogName}}s behov!',
      header: '{{dogName}}s skræddersyede foderplan',
    },
    recommended_products: 'Anbefalet produkter ',
  },
  nutritions_daily_title: 'Daglig portion: {{dailyGrams}} g / {{dailyDl}} dl',
  orders: {
    load_all_receipts: 'Vis alle',
  },
  payment: {
    method: {
      kco: 'Klarna',
      missing: 'Betalingsmetode mangler',
      stripe: 'Kredit- eller betalingskort (Stripe)',
    },
  },
  price: {
    free: 'GRATIS',
    total: 'Pris i alt',
    from_per_kg: 'Fra {{price}}/kg',
    starting_from: 'fra {{price}}',
    from: 'fra {{price}}',
  },
  product_info: {
    benefits: 'Sundhedsmæssige fordele',
    carbon_pawprint: 'CO2-poteaftryk',
    ingredients: 'Ingredienser',
    nutrients: 'Næringsindhold',
    complexes: 'Sundhedsmæssige fordele\n',
  },
  recipe: {
    all_ingredients: {
      title: 'Alle ingredienser',
    },
    nutritional_content: 'Samlet næringsindhold',
    additional_benefits_title: 'Opskriften er også god for:',
    needs_covered_by_benefits_title: 'Opskriften er godt til:',
  },
  recommendation_group: {
    good: 'Passende alternativ',
    notSuitable: 'Ikke anbefalet',
    recommended: 'Vores anbefaling',
  },
  subscription_details: {
    included_products: 'Produkter',
    plus_shipping: '+ Levering',
  },
  targeted_upsell: {
    section_heading: 'Udvalgt specielt til {{dogName}}',
  },
  benefits: {
    rewards: {
      claim: 'Anvend',
      claimed: 'Anvendt',
      order: 'Bestilling',
      order_0: 'Velkomstfordel',
      reward_information:
        'Alvars loyale venner får muligheden for at prøve vores produkter gratis. 💚 Bestiller du regelmæssigt, får du loyalitetsfordele. Det produkt du vælger vil blive tilføjet til din næste ordre. Fordelen aktiveres i din næste levering.',
      reward_terms: 'Vilkår for belønninger',
      rewards_for_dog_name: '{{dogName}}s Alvar-fordele',
      timeline_title: 'Din belønningshistorik',
      you_have_x_unclaimed_rewards: 'Du har {{count}} uindløste fordele',
      coming_up: 'Kommer snart',
      youre_here: 'Du er her:',
      next: 'Kommer næste gang',
      previous: 'Forrige',
      activation_callout:
        'For at modtage og benytte dine fordele, skal du have et aktivt abonnement.',
      new_reward_in: 'Ny belønning er tilgængelig om',
      new_reward_in_orders: 'Bestillinger',
      new_reward_in_order: 'bestillinger',
    },
    page_header: 'Fordele som Alvarfan',
    navigation_option: {
      coupon: 'Rabatkoder',
      referral: 'Anbefaling',
      rewards: 'Loyalitetsfordele',
    },
    coupon: {
      explainer:
        'Bekræft rabatkoden i feltet ovenfor, for at få den med ved din næste bestilling. Fordi du er en loyal Alvarfan, vil vi rigtig gerne sende en del tilbud og rabatkoder til dig, så husk at hold øje med din indbakke!',
      header: 'Har du en rabatkode?',
    },
    referral: {
      activation_callout:
        'Hej! 😍 For at indløse din henvisningsbonus, skal du have et aktivt abonnement.',
    },
  },
  checkout: {
    account_info: 'Kontoinformation',
    add_payment_methods: 'Tilføj betalingsinformation',
    add_shipping_methods: 'Tilføj leveringsinformation',
    delivery_method: 'Leveringsmetode',
    delivery_method_text:
      'Hvordan og hvor vil du gerne have din levering leveret?',
    do_order: 'Bestil',
    order: 'Bestilling',
    payment_info: 'Betalingsinformation',
    shipping_info: 'Leveringsinformation',
    add_shipping_method: 'Tilføj leveringsinformation\n',
    account: 'Opret konto',
    account_set_up: 'Opret konto',
    verify_shipping_info: 'Bekræft addresse',
    confirm_subscription: 'Til bestilling',
  },
  delivery: {
    free: 'Gratis',
    checking_availability: 'Finder muligheder',
    demo_shipping_price: 'Levering',
    free_delivery_on_orders_over_spend:
      'Gratis levering på ordrer over {{spend}}',
    option_not_available: 'Ikke tilgængelig med det valgte postnummer',
    regular_price_for_subs_under_x:
      'Gratis levering på abonnementer over {{minimumSpend}} efter prøvepakken.',
  },
  edit_order: {
    quantity_lower_than_recommended:
      'Woof. Tjek om du ønsker at reducere leveringsintervallet.',
    quantity_higher_than_recommended:
      'Woof. Tjek om du ønsker at øge leveringsintervallet.',
  },
  emission_card: {
    header: 'Foderplanens klimaaftryk',
    kg_saved_per_year_with_subscription: '{{dogName}} sparer {{kg}} kg CO2e',
    percent_saved:
      'Det er <b>{{percent}}%</b> mindre end med det gennemsnitlige premium tørfoder.<br/>Og hvad der er tilovers kompenserer vi for.',
    savings_equal_to_times_sauna:
      'årligt med sin nye foderplan. Det er det samme som at opvarme en elektrisk sauna {{timesSauna}} gange!',
    by_joining_alvar_dog_name_saves_kg:
      'Med alvar vil du og {{dogName}} spare ca. ',
    kg_co2_per_year: '{{kg}} kg CO2 / år\n',
    this_equals_times_sauna:
      'Det er det samme som at opvarme en elektrisk sauna {{timesSauna}} gange.',
  },
  forms: {
    errors: {
      invalid_phone_number: 'Ugyldigt telefonnummer',
    },
  },
  links: {
    edit: 'Ændre',
    show_all: 'Vis alt',
    save: 'Gem',
    view_products: 'Se produkter',
  },
  macros: {
    fat: 'Fedt',
    fiber: 'Fiber',
    kcal: 'Kcal',
    protein: 'Protein',
  },
  macro: {
    fat: 'Fedt',
    fiber: 'Fiber',
    protein: 'Protein',
    energy: 'kcal / 100g\n',
    kcal: 'kcal / 100g\n',
  },
  order_hero: {
    button_text: {
      edit_order: 'Foretag ændringer i bestillingen',
    },
    status: {
      active: 'Aktiv',
    },
  },
  order: {
    add_treat: 'Hvad med disse?',
    configure_dog_names_subscription: '{{dogName}}s <b>Foderabonnement</b>',
    demo_price: 'Prisen på prøvepakken',
    dog_names_demo_order: '{{dogName}}s <b>prøvepakke</b>',
    next_delivery: 'Den næste ordre vil blive pakket\\n',
    price_and_then:
      'Og derefter, hvis du vælger at fortsætte med dit abonnement',
    sample_total: 'Prøvepakkens samlede pris',
    subscription_intro:
      'Prøvepakken starter et bindingsfrit abonnement. Det er praktisk, fuld af fordele og meget fleksibelt: Du kan til enhver tid ændre eller annullere dine leveringer på din personlige kundeside. Bemærk, at datovalget nedenfor gælder for din efterfølgende ordre – prøvepakken vil ankomme uafhægig af denne dato.',
    download_receipt: 'Download kvittering',
    errors: {
      something_went_wrong: 'Åh nej! Noget gik galt.',
    },
    next_delivery_for_dog_name: '{{dogName}}s næste ordre',
    order_history: 'Bestillinger',
    orders: 'Abonnement',
    you_can_edit_order_until:
      'Du kan ændre din bestilling frem til d. {{untilDate}}',
    coupon_confirm: 'Bekræft',
    coupon_label_1: 'Har du en rabatkode?',
    coupon_label_2: 'Indsæt kode',
    completed: 'Fuldført',
    history_product_plural: 'Produkter',
    total: 'Abonnement i alt:',
    subscription_intro_name_grams:
      '{{dogName}} bør spise {{dailyGrams}}g tørfoder pr. dag, så vi mener dette er et passende interval. Men du kan selv justere det til en hver tid!',
    on_hold: 'Abonnementet er sat på pause',
    dog_names_single_order: '{{dogName}}s <b>bestilling</b>\n',
    every_x_weeks: 'Hver {{weeks}}. uge',
    interval: 'Leveringsinterval',
    per_x_week: 'Hver {{weeks}}. uge',
    this_order: 'Denne bestilling',
    delivery_interval: 'Levering hver {{interval_weeks}}. uge',
    history_product: 'Produkt',
  },
  product_options: {
    recommended: 'Anbefaling',
  },
  'puppy-box-423575': 'Fisk',
  'puppy-box-423589': 'Kylling',
  'puppy-box-423651': 'Kylling',
  'puppy-box-423652': 'Fisk',
  'puppy-box-423838': 'Fisk',
  'puppy-box-423857': 'Kylling',
  starting_price: {
    from_per_kilo: 'Fra<br/>{{startingPrice}}<br/>/kg',
  },
  feedback: {
    labels: {
      message: 'Min besked',
      subject: 'Emne for feedback',
    },
    subjects: {
      delivery: 'Levering',
      feedback: 'Feedback',
      feeding: 'Ernæring',
      order: 'Abonnement',
      other: 'Andet',
      payment: 'Betaling',
      products: 'Produkter',
      reclamation: 'Reklamation',
    },
  },
  subscription: {
    coupon_field: {
      disabled_help_text:
        'Aktiver dit abonnement, for at kunne tilføje din rabatkode',
    },
    next_order_comes: 'Næste levering for {{dogName}} kommer',
    welcome_back: 'Velkommen tilbage',
    add_treats: 'Tilføj fordele og mere',
    cancel: 'Opsige abonnement',
    delivery_and_payment: 'Leverings- og betalingsoplysninger',
    dog_names_subscription: '{{dogName}}s <b>Abonnement</b>',
    next_order_comes_after:
      'Din næste ordre til {{dogName}} bliver afsendt om {{days}} dage! Du kan foretage ændringer i leveringen indtil d. {{untilDate}}.',
    sub_total: 'Subtotal',
    summary: {
      delivery: 'Levering',
      x_to_free_delivery: '<b>{{spend}}</b> til gratis levering\\n',
    },
    update: 'Opdater',
    update_success: 'Ændringer gemt',
    some_recent_order_in_completed_state:
      '{{dogName}} behøver ikke at vente længere. Leveringen er på vej!',
    some_order_needs_payment:
      'Der var et problem med din ordre. Klik her for at fortsætte.',
    some_recent_order_in_processing_state:
      '{{dogName}}s ordre er ved at blive pakket',
    welcome_back_name: 'Velkommen tilbage, {{name}}!',
    activate: 'Aktiver dit abonnement',
  },
  thank_you: 'Mange tak',
  wrapped: {
    alvar_intro: {
      body: 'Tak fordi du har være med til at minimere CO2-poteaftrykket hos os i år. Vi er stolte af at kunne præsentere din hunds CO2-besparelse.',
      heading: 'Furwell 2022 🐾',
    },
    dog_names_year_wrapped: '{{dogName}}s 2022 wrapped',
    download_image: 'Download billedet',
    home_page_notification: {
      body: 'Nu hvor 2022 er ved at nå sin ende, kan du se hvor meget CO2-udledningen du har reduceret i 2022.🐾',
      button: 'Få dit 2022 Wrapped',
      heading: "It's a wrap!",
    },
    how_did_we_calculate: {
      body: '<li>1. Beregnet mængden af ​​tørfoder fra Alvar som {{dogName}} har spist i 2022. Rapporten gælder kun tørfoder - indtil videre. </li> <li>2. Beregnet foderblandingens CO2-udledning </li> <li>3. Sammenlignet emissionerne fra foderblandingen med emissionerne fra et gennemsnitligt premium tørfoder (The global environment paw print of pet food, Alexander et al. 2020) </li> <li>4. Sammenlignet reduceringen af emissioner med udledningen fra en elektrisk sauna (OpenCO2.net) </li> <br/><p>P.S. Vi gemmer ikke dit billede nogle steder. </p> <p>P.P.S. Godt nytår! </p>',
      heading: 'Sådan har vi beregnet dette tal:',
    },
    intro:
      'Tilføj et billede af din hund, gem billedet på din telefon og del det på de sociale medier (tag os gerne @alvarpet.dk)!',
    sorry_no_orders:
      'Beklager, vi kan desværre ikke finde nogen ordrer for din hund i år 2022.',
    upload_image: 'Tilføj et billede af din hund',
    upload_new_image: 'Skift billede',
  },
  form: {
    contact: {
      submit: 'Send',
      success: {
        heading: 'Mange tak!',
        message:
          'Mange tak! Vi vender snarest muligt tilbage. Vær sød ikke også at sende den samme besked til vores mail - medmindre du selvfølgelig har noget nyt at tilføje!  Vi sætter stor pris på alt slags feedback, spørgsmål, tanker eller forslag, da det er med til, at kunne forbedre Alvar.',
      },
    },
    labels: {
      email: 'Mail',
    },
    delivery: {
      matkahuolto: 'Matkahuolto',
      post: 'Afhentningssted',
      submitted_notification: 'Gemt!',
      home: 'Hjemmelevering',
      errors: {
        dog_weight: {
          too_high: 'Hundens vægt kan ikke overstige 200 kg',
          too_low: 'Hundens vægt skal være over 0 kg',
          email_already_in_use: 'Denne mail er allerede i brug',
          email_already_in_use_please_login:
            'Denne mail er allerede i brug - venligst log ind',
          invalid_date: 'Ugyldig dato',
          invalid_day: 'Ikke en gyldig dag',
          invalid_month: 'Ugyldig måned',
          month_only_has_x_days: 'Den indtastede måned har kun {{days}} dage',
          must_be_number: 'Skal være et tal',
          must_feed_dry_food: 'Kosten skal indeholde tørfoder',
          no_negative_allowed: 'Værdien må ikke være negativ.',
          no_options_available:
            'Beklager, vi kunne ikke finde et passende alternativ',
          not_valid_email: 'Kontrollér emailadressen',
          not_valid_postcode: 'Ugyldigt postnummer',
          passwords_not_match: 'Passwordene matcher ikke',
          postal_code_needed: 'Postnummer kræves',
          postcode_needed: 'Postnummer kræves',
          required: 'Obligatorisk felt',
          sum_too_high: 'Summen må ikke overstige 100%',
          too_short_password: 'Passwordet er for kort',
        },
      },
      bring: 'Bring',
      budbee: 'Budbee',
      postnord: 'PostNord',
      instabox: 'Instabox',
    },
    placeholder: {
      autocomplete: 'Skriv her',
      autocomplete_disabled: 'Race ikke fundet',
      birthday: 'dd.mm.yyyy',
      custom_breed_name: 'Mangler race',
      day_format: 'dd',
      email: 'eksempel@e-mail.dk',
      month_format: 'mm',
      select: 'Vælg...',
      year_format: 'åååå',
      coupon_field: 'Rabatkode',
    },
    errors: {
      invalid_phone_code: 'Ugyldig landekode',
      dog_weight: {
        too_high: 'Hundens vægt kan ikke overstige 200 kg',
        too_low: 'Hundens vægt skal være over 0 kg',
      },
      email_already_in_use: 'Denne mail er allerede i brug',
      email_already_in_use_please_login:
        'Denne mail er allerede i brug - venligst log ind',
      invalid_date: 'Ugyldig dato',
      invalid_day: 'Ikke en gyldig dag',
      invalid_month: 'Ugyldig måned',
      month_only_has_x_days: 'Den indtastede måned har kun {{days}} dage',
      must_be_number: 'Skal være et tal',
      no_negative_allowed: 'Værdien må ikke være negativ.',
      must_feed_dry_food: 'Kosten skal indeholde tørfoder',
      no_options_available:
        'Beklager, vi kunne ikke finde et passende alternativ',
      not_valid_email: 'Kontrollér emailadressen',
      not_valid_postcode: 'Ugyldigt postnummer',
      passwords_not_match: 'Passwordene matcher ikke',
      postal_code_needed: 'Postnummer kræves',
      postcode_needed: 'Postnummer kræves',
      recommendation_engine_create_account_token_invalid:
        'Åh nej - din session er udløbet. Venligst prøv igen',
      recommendation_engine_create_account_token_invalid_link_text: 'Prøv igen',
      required: 'Obligatorisk felt',
      sum_too_high: 'Summen må ikke overstige 100%',
      sum_too_low: 'Summen af kosten skal udgøre 100 %',
      too_short_password: 'Passwordet er for kort',
      must_include_diet_item:
        '{{dogName}} spiser da noget, ikke? \nVælge venligst mindst en foderkategori.',
    },
    warning: {
      unsaved_changes:
        'Du har ikke gemt dine ændringer. Er du sikker på du vil forlade siden?',
    },
    submission_failed:
      'Noget gik galt! Venligst prøv igen eller kontakt vores kundeservice',
    submit_new_password: 'Indsend en ny adgangskode',
    loading: 'Indlæser muligheder...',
    confirm: 'Bekræft',
  },
  dates: {
    date_no_future: 'Fremtidige datoer ikke tilladt',
    date_not_valid: 'Ugyldig dato',
  },
  labels: {
    delivery_pickup_point: 'Afhentningssted',
    billing_method: 'Betalingsmetode',
    drop_point: 'Afhentningssted',
    email_user_account: 'Mail (bruger)',
    last_name: 'Efternavn',
    name: 'Navn',
    password: 'Adgangskode',
    password_repeat: 'Gentag adgangskode',
    phone_prefix: 'Landekode',
    shipping_address: 'Adresse',
    shipping_city: 'By',
    shipping_country: 'Land',
    shipping_postcode: 'Postnummer',
    starting_date: '\\nNæste ordre efter prøvepakken\\n',
    contact_info: 'Kontaktinformation',
    interval: 'Leveringsinterval',
    invoices: 'Kvitteringer',
    payment_info: 'Betalingsinformation',
    shipping_info: 'Leveringsinformation',
    email: 'Mail',
    country: 'Sendes til',
    delivery: {
      email_opt_in: 'Ja, tak! Send mig gode tilbud fra Alvar',
    },
    save_dog_info: 'Gem mine oplysninger og opret en bruger',
    email_opt_in: 'Ja, tak! Send mig gode tilbud fra Alvar',
    shipping_interval: 'Leveringsinterval',
    shipping_size: 'Tørfoder pr. levering',
    delivery_type: 'Leveringstype',
    shipping_date: 'Den næste ordre vil blive pakket\n',
    first_name: 'Fornavn',
    home_delivery: 'Leveringsservice',
    phone: 'Telefonnummer',
    delivery_address: 'Leveringsadresse',
    card: {
      card_number: 'Kortnummer',
      cvn: 'CVN',
      expiration: 'Udløbsdato',
    },
    bundle_size: 'Sampakningsstørrelse',
    current_password: 'Nuværende kode',
    delay_next_order_modal: {
      interval: 'Leveringsinterval\n',
      payment_date: 'Næste ordre:\n',
    },
    new_password: 'Nyt password',
    new_password_repeat: 'Gentag password',
    address: 'Adresse',
    city: 'By',
    po_box: 'Afhentningssted',
    zip: 'Postnummer',
  },
  loading: 'Henter pinde og data🐾',
  product_card: {
    about_product: 'Produktinformation',
    more_information: 'Tjek opskriften ud',
  },
  confirm: 'Bekræft',
  action: {
    change_recipe: 'Skift opskrift',
    add: 'Tilføj',
    add_to_order: 'Tilføj til din ordre',
    change_dog: 'Skift hund',
    remove: 'Fjern',
    go_to_next: 'Næste',
    show_more: 'Vis flere',
  },
  image_alt_text: {
    activity_level_1: 'Inaktiv hund',
    activity_level_2: 'Mellemaktiv hund',
    activity_level_3: 'Meget aktiv hund',
    body_type_1: 'Tynd',
    body_type_2: 'ideal',
    body_type_3: 'Kraftig',
    preferred_ingredient: {
      chicken: 'Kylling',
      duck: 'And',
      fish: 'Fisk',
      grainFree: 'Kornfri',
      insect: 'Insekt',
      noPreference: 'ingen præferencer',
      plant: 'plante',
    },
    diet_item: {
      dryFood: 'Tørfoder',
      other: 'Andet',
      snack: 'Godbidder',
      wetFood: 'Vådfoder',
      treat: 'Godbidder\n',
    },
    illustrations: {
      home_delivery: 'hjemmelevering',
      loyalty_benefits: 'gaver',
      modify_cancel_any_time:
        'en hund der glæder sig til at smage Alvars foder',
      lower_prices: 'tilbud',
    },
  },
  hint: {
    diet_composition: {
      dietShareOther: 'Andel af foder, andet',
    },
  },
  forgot_password: 'Glemt kode?',
  validation_suggestion: {
    diet_composition: {
      reduce_to_match: 'Reducer for at afstemme',
      sum_less_than_100_percent: 'Øg tørfoder for at matche',
      sum_more_than_100_percent: 'Reducer tørfoder for at matche',
      uses_raw_food: 'Sæt til 0%',
      wet_over_recommended: 'Sæt til 50%',
    },
  },
  warning: {
    diet_composition: {
      sum_exceeds_100: 'Summen af foderet overstiger 100%.',
      sum_less_than_100_percent: 'Summen af kosten skal udgøre 100%.',
    },
  },
  diet_legend: {
    dry: 'Tørfoder',
    other: 'Andet / råt',
    supplement: 'Kosttilskud',
    treats: 'Godbidder',
    wet: 'Vådfoder',
  },
  food: {
    nutritions: {
      recommended: {
        lead: 'Den optimale fodermængde for {{dogName}} er {{dailyGram}} g/dag. Dette er baseret på {{dogName}}s alder, race og livsstil',
      },
    },
    preferred_ingredient: {
      chicken: 'Kylling',
      duck: 'And',
      fish: 'Fisk',
      grain_free: 'Kornfri',
      insect: 'Insekt',
      no_preference: 'Ingen præferencer',
      plant: 'Plante',
    },
    for_special_need: {
      activeDog: 'Aktiv hund',
      agingDog: 'Ældre hund',
      brainWellness: 'Mental velvære',
      delicateStomach: 'Sensitiv mave',
      grainFree: 'Kornfri',
      greedyEater: 'Madglad hund',
      growingDog: 'Voksende hund',
      itchyPaws: 'Kløende poter',
      jointStiffness: 'Stive led',
      loosePoop: 'Tynd mave',
      neutered: 'Kastreret/Steriliseret',
      pickyEater: 'Kræsen',
      sensitiveSkin: 'Sensitiv hud',
      tendencyToGainWeight: 'Tendens til at tage på',
    },
    covers_x_weeks: 'dækker {{weeks}} uger',
  },
  shipping_countries: {
    se: 'Sverige',
    no: 'Norge',
    fi: 'Finland',
    dk: 'Danmark',
    de: 'Tyskland',
  },
  units: {
    after_days: 'efter dage',
    bottle: 'flaske',
    can: 'dåse',
    dl: 'dl',
    from: 'fra',
    grams: 'g',
    hour: 'T',
    kcal: 'kcal',
    kilos: 'kg',
    ml: 'ml',
    pcs: 'stk',
    price_starting_abbr: 'fra',
    product_plural: 'produkter',
    sample_order: 'prøvepakke',
    product_singular: 'produkt',
    tbsp: 'spsk',
    today: 'i dag',
    week: 'uger',
    weeks: 'uger',
    weeks_abbr: 'uger',
    x_pack: '{{x}}-pack',
  },
  wizard: {
    next: 'Næste',
    submit: 'Send',
  },
  dog: {
    gender: {
      male: 'Han',
      female: 'Hun',
    },
    diet_composition: {
      intro:
        'Standarden er 100% tørfoder – juster fodersammensætningen nedenfor!',
    },
    intro_text:
      'Ud fra dine oplysninger, skræddersyer vi en foderplan til din prøvepakke.',
    title: 'Hej, Jeg er Alvar. Fortæl os lidt om din hund!',
    update_success: 'Ændringerne er gemt!',
    placeholder: {
      weight_estimate: 'Vores estimat',
      enter_weight: 'Indtast voksenvægt (et estimat er fint)',
      our_weight_estimate:
        'Indtast voksenvægt (vores estimat er {{estimate}} kg)',
    },
    birthday_too_old: 'Haha, din hund er vel ikke {{age}} år gammel?',
    add_new: 'Tilføj en ny',
    add_another: 'Tilføj endnu en hund',
    add: 'Tilføj hund',
    carbon_paw_print_title: '{{dogName}}s CO2 poteaftryk',
    body_type_modal: {
      header: 'Hvordan vurderer du din hunds kropsbygning?',
      body: 'En hund med normal kropsvægt og -bygning bør have en tydelig talje, og ribbenene skal være lette at mærke under pelsen. Giv din hund et kram!',
    },
    label: {
      preferred_ingredients: 'Foretrukne ingredienser',
      diet_share_dryFood: 'Tørfoder',
      diet_share_other: 'Andet',
      diet_share_snack: 'Godbidder',
      diet_share_wetFood: 'Vådfoder',
      diet_share_treat: 'Godbidder\n',
    },
    no_special_needs: 'Ingen specielle behov',
    body_condition: {
      '3': 'Tynd',
      '5': 'Ideal',
      '7': 'Kraftig',
      choose: 'Vælg',
    },
    titles: {
      activity: 'Aktivitet',
      diet_composition: 'Hvilken sammensætning ønsker {{dogName}}?',
      preferred_ingredients: 'Hvad vil {{name}} helst have i sin madskål?',
    },
    labels: {
      diet_items: '{{dogName}}s måltider består af:',
      gender: 'Køn',
      special_needs: 'Specielle behov',
      weight: 'Voksenvægt',
      birthday_field: {
        day: 'Dag',
        hint: 'For eksempel 19 07 2019 (Som Alvar!)',
        legend_with_name: 'Hvornår har {{name}} fødselsdag?',
        legend_without_name: 'Hvornår har din hund fødselsdag?',
        month: 'Måned',
        year: 'År',
      },
      breed: 'Race (har du en blandingsrace skriv "blandingshund")',
      body_condition: 'kropstilstand',
      birthday: 'Fødselsdag',
      activity: 'Er {{dogName}} i god form?',
      body_type: 'Kropsbygning',
      diet_composition: 'Hvilken sammensætning ønsker {{dogName}}?',
      preferred_ingredients: 'Hvad vil {{name}} helst have i sin madskål?',
      dog_name: 'Hundens navn',
      account_action: 'Log ind eller opret konto',
    },
  },
  nav: {
    home: 'Hjem',
    back_to_marketing_site: 'Tilbage til alvarpet.com',
    marketing_site: 'Tilbage til alvarpet.com',
    recommendation: {
      confirm_navigation:
        'Vil du forlade foderguiden? Du kan miste dine indtastede oplysninger 🐾',
    },
    benefits: 'Fordele',
    breeders_club: 'For opdrættere',
    frontpage: 'Hjem',
    login: 'Log ind',
    logout: 'Log ud',
    my_herd: 'Min flok',
    orders: 'Abonnement',
    products: 'Produkter',
    profile: 'Min profil',
    recommendation_engine: 'Foderrådgiver',
    recommendation_machine: 'Foderrådgiver',
    recommendation_machine_confirmation:
      'Du er stadig i gang med at udfylde foderrådgivningen. Vil du starte forfra?',
    recommendation_machine_restart: 'Nulstil',
    my_sustainability: 'Mit CO2-poteaftryk',
  },
  page_not_found: 'Vi kunne desværre ikke finde den efterspurgte side',
  contact_info: {
    email: 'hello@alvarpet.com',
    phone_de: '+49 30 20995974',
    phone_dk: '+358 10 200 7350',
    phone_fi: '+358 10 200 7350',
    phone_no: '+358 10 200 7350',
    phone_se: '+358 10 200 7350',
    take_contact: 'Kontakt os',
    take_contact_lead: 'Noget på hjerte? Spørgsmål eller forespørgsler?',
  },
  channel: {
    email: 'Kontakt os',
    facebook: '@alvarpet.dk',
    instagram: '@alvarpet.dk',
  },
  footer: {
    agb: 'Vilkår',
    datenschutz: 'Privatliv',
    disclaimer: '© 2022 ALVAR PET OY. FOOD FOR THE CONSCIOUS PUP.',
    impressum: 'Impressum',
    privacy_policy: 'Fortrolighedspolitik',
    widerruf: 'Returnering',
  },
  recommendation: {
    manage_secondary: 'Ændre / Fjern',
    feeding_guide: {
      heading: 'Fodervejledning',
      calorie_overview: {
        heading: 'Fordeling af kalorier',
      },
      lead: 'Anbefalet sammensætning og mængde, som {{dogName}} bør spise dagligt for at forblive glad & sund.',
      macros: {
        heading: 'Makronæringsstoffer',
      },
    },
    diet_plan_intro: {
      heading: '{{dogName}}’s skræddersyede foderplan',
      lead: 'Her er vores anbefalede foderplan, der er skræddersyet til {{dogName}}s behov. Du kan ændre indholdet af prøvepakken på næste side.',
    },
    consideration_slide: {
      activity: {
        active:
          'Fordi {{dogName}} er ekstra aktiv, øger vi den daglige portionsstørrelse en smule',
        lazy: 'Vi justerer den daglige mængde, så den passer til {{dogName}}s afslappede livsstil',
        normal:
          'Vi tilpasser den daglige mængde så den passer til {{dogName}}s livsstil',
      },
      age: {
        adult:
          'Vi tilpasser anbefalingen så den passer til {{dogName}}s individuelle behov',
        puppy:
          'Vi sammensætter en anbefaling, der passer til specifikt til hvalpes behov',
        senior:
          'Vi sammensætter en anbefaling, der passer til specifikt til en ældre hunds behov',
      },
      bcs: {
        chubby:
          'Vi vælger et foder med lavt kalorieantal, for at hjælpe {{dogName}} med vægten, uden at sulte',
        ideal:
          '{{dogName}} vil få dækket alle sine behov med naturlige ingredienser af høj kvalitet',
        skinny:
          'For at hjælpe med vægten, vælger vi en opskrift med højere kalorieantal til {{dogName}}',
      },
      special_need: {
        heartBurn:
          'For at undgå halsbrænd, vælger vi en opskrift til {{dogName}}, der har et lavt fedtindhold',
        neutered:
          'Vi vælger en opskrift, der tager hensyn til at {{dogName}} er steriliseret og tilpasser mængden.',
        pickyEater:
          'Fordi {{dogName}} er kræsen, sammensætter vi en ekstra lækker anbefaling',
      },
    },
    diet_plan_heading: '{{dogName}}s skræddersyede foderplan',
    diet_plan_lead:
      '<b>Her er den, din hunds optimale og miljøvenlig foderanbefaling.</b> 😍🐾 Du kan gennemse og redigere produkterne herunder.',
    show_recommendation: 'Vis anbefaling',
    pages: {
      ingredients: {
        disclaimer:
          'Vi har muligvis ikke en opskrift med monoprotein, som passer helt til {{name}} endnu - men vi vil gøre vores bedste!',
      },
      email: '{{dogName}}, din foderplan er klar!',
      email_loading: 'Sammensætter foderplan til {{dogName}}',
      activity: 'Aktivitet',
      diet_composition: 'foderandel',
      dog_info: 'Info om din hund',
      ingredients_page_title: 'Foretrukne ingredienser',
      recommendation: 'Vores anbefaling',
      specialNeeds: 'Specielle behov',
    },
    form: {
      is_breeder: {
        tooltip:
          'Opdrætter? Meld dig ind i Alvars klub for opdrættere! Vi sender detaljerne via mail',
      },
      is_breeder_label: 'Jeg er en opdrætter',
      demo_order: 'Næste',
      terms_conditions_link: 'Handelsbetingelser',
      terms_conditions: 'Jeg accepterer',
    },
    restart_confirmation: 'Nulstil foderguiden',
    session_expired:
      'Din sesssion er udløbet. Indtast venligst din email igen.',
    subscription_ksp: {
      body: {
        home_delivery: 'Nyd belejligheden ved jævnlige leveringer',
        loyalty_benefits:
          'Vi forkæler vores abonnenter med ekslusive tilbud og gaver. ',
        modify_cancel_any_time:
          'Lav ændringer, udsæt og annuller dit abonnement til en hver tid.',
        lower_prices:
          'Abonnenter betaler <b>10% mindre</b> for deres leveringer. Altid.',
      },
      heading: {
        home_delivery: 'Direkte til hundedøren',
        loyalty_benefits: 'Ikke kun kløen bag øret\n',
        modify_cancel_any_time: 'Fleksibelt efter dine behov',
        lower_prices: 'Den bedste pris til de bedste venner',
      },
      section_heading: 'Hvad sker der når jeg bestiller?',
    },
    referral_link_text: 'Giv 100 kr. - Få 100 kr.\n',
    thank_you:
      '<p>Vi er så glade for, at du er blevet en del af vores rejse mod et minimeret CO2-poteaftryk. Sammen har vi allerede sparet mere end 2.810.632 kg CO2e!\n</p> <p><strong>Vil du hjælpe os med at vokse og invitere dine hundevenner med på rejsen?</strong></p>',
    thank_you_add_another_notice: ' ',
    dog_names_meal_plan_product_count:
      'Vi har sammensat en foderplan til {{dogName}}, der består af de følgende {{productCount}} produkter.',
    order_overview: 'Gå til bestilling',
    our_recommendation: 'Vores anbefaling',
    thank_you_add_dog: 'Tilføj endnu en hund',
    cart_is_empty: {
      body: 'Klik nedenfor, for at bestille den oprindelige anbefaling, eller tilføj andre produkter.',
      header: 'Kurven er tom',
    },
    change_interval: {
      explainer:
        'Leveret hver {{weeks}}. uge<br/>Tilpas eller annuller til en hver tid.\n',
      made_to_fit: 'Tilpasset til dit skema',
    },
    reset_cart_to_recommendation: 'Gendan kurven',
    secondary_product: {
      remove: 'Fjern',
    },
    treats_feeds_treats: {
      'ALV-202':
        'Vi har hørt at {{dogName}} har en "sød tand". Alvars Nuggets er et sundt og lækkert valg til den daglige træning og forkælelse.',
    },
    wet_food_feeds_other: {
      'ALV-402':
        "Alvar's kyllingetopper tilføjer variation til de daglige måltid, for hunde some {{dogName}}, der ikke er vant til kun at spise tørfoder.",
    },
    wet_food_feeds_wet: {
      'ALV-402':
        'Kyllingetopper er en lækker og sund måde at berige måltidet, for en hund som {{dogName}}, der er vant til at spise både tør- og vådfoder.\n',
    },
  },
  accessibility: {
    close_menu: 'Luk menu',
    open_menu: 'Åben menu',
    open_options: 'Se alternativer',
  },
  navigation: {
    go_to_x: 'Gå til',
  },
  page_title: {
    sustainability: 'Min floks klimaindsats',
    order: 'Bestil',
    wrapped: '2022 Wrapped',
    benefits: {
      coupon_page: 'Rabatkoder',
      frontpage: 'Alvars fordele',
      referral_page: 'Anbefalinger',
      rewards_page: 'Belønninger',
    },
    breeders: {
      benefits_page: 'Fordele for opdrættere',
      frontpage: 'Alvars Opdrætterklub',
      information_page: 'Nyttig information',
      puppies_page: 'Venter du hvalpe?',
    },
    frontpage: 'Kundesiden',
    local_products_lander: 'Nul-emission tørfoder fra Finland',
    my_herd: {
      edit: 'Foretag ændring',
      frontpage: 'Min flok',
    },
    orders: {
      edit: 'Foretag ændring',
      frontpage: 'Bestillinger',
    },
    profile: {
      edit_payment: 'Foretag ændring',
      edit_shipping: 'Leveringsadresse',
      edit_user: 'Foretag ændring på din profil',
      frontpage: 'Profil',
    },
    recommendation_machine: 'Foderrådgiver',
  },
  meta_description: {
    recommendation:
      'Få en skræddersyet foderplan tilpasset din hunds unikke behov og bestil en prøvepakke leveret direkte til døren fra {{demoPrice}}.',
    frontpage: 'Log ind på din kundeside og administrer dit abonnement.',
  },
  password: 'Adgangskode',
  email: 'email',
  login: {
    login_in: 'Log ind',
    button: 'Log ind',
    log_in: 'Log ind',
    remember_me: 'Husk mig',
    errors: {
      login_failed: 'Login mislykkedes. Prøv venligst igen.',
    },
  },
  product: {
    clearance_nuggets: {
      long: 'Zero waste deals: Nuggets',
      short: 'Zero waste deals: Nuggets',
    },
    clearance_fish_oil: {
      short: 'Zero waste deals: Vildfiskeolie',
      long: 'Zero waste deals: Alvars Vildfiskeolie 100 ml',
    },
    info_text: 'Produktdetaljer',
    puppy_box: 'Alvars hvalpepakke',
    bug_sample: 'Bug',
    serene_sample: 'Serene',
    slender_sample: 'Slender',
    zippy_sample: 'Zippy',
    german_fish_and_chips: {
      long: 'Zero waste deals: Fish & Chips',
      short: 'Fish & Chips',
    },
    steady_sample: 'Steady',
    root_sample: 'Root',
    'zero-emission': {
      accessory: 'Alvars tilbehør',
      chew: 'Alvars Tyggeben',
      dryFood: 'Alvars Nul-emission tørfoder',
      kibble: 'Alvars Nul-emission tørfoder',
      puppyBox: 'For hvalpe',
      sauce: 'Alvars nul-emssion kosttilskud',
      snack: 'Alvars nul-emission godbidder',
      supplement: 'Alvars nul-emssion kosttilskud',
      wetFood: 'Alvars nul-emssion topper',
      toy: 'Alvars Upcycled Toy',
      treat: 'Alvars godbidder',
    },
    out_of_stock: 'Midlertidigt udsolgt\n',
    view: 'Se mere',
  },
  puppy_box: {
    item: {
      tote_bag: 'Tote bag',
    },
    description:
      'En hvalpepakke består af et udvalg af vores produkter, som vil hjælpe hvalpene med et CO2-frit poteaftryk.',
  },
  product_category: {
    accessory: 'Tilbehør',
    chew: 'Tyggeben',
    dryFood: 'Tørfoder',
    toy: 'Legetøj',
    long: {
      dryFood: 'Alvars Nul-emission tørfoder',
      chew: 'Alvars tyggeben',
      accessory: 'Alvars hundetilbøher',
      supplement: 'Alvars olier',
      wetFood: 'Alvars vådfoder',
      treat: 'Alvars godbidder\n',
    },
    all: 'Alle',
    sauce: 'Tilskud',
    snack: 'Godbidder',
    supplement: 'Olier',
    wetFood: 'Vådfoder',
  },
  unit: {
    kg_co2e: 'kg CO2e',
    '%': '%',
  },
  change: 'Ændre',
  reward_block: {
    step_1_description:
      'Din ven får {{discount}} i rabat på deres første ordre, når de bestiller via dit link.',
    step_1_title: 'Del med en ven',
    title: 'Del og få fordele',
    share_button: 'Del og optjen fordele',
    step_2_description:
      'Du får {{discount}} rabat for hver henvisningskode som dine venner bruger. Du kan maksimalt få din henvisningsbonus 20 gange om året.',
    step_2_title: 'Optjen fordele',
  },
  ally_text: {
    change_subscription_configuration: 'Ændr abonnementsindstillinger',
    edit_address: 'Rediger addresse',
    edit_subscription: 'rediger abonnement',
    decrease_quantity: 'reducer mængden',
    increase_quantity: 'øg mængden',
  },
  recommended_option: 'Anbefalet',
  subscription_configuration: {
    additional_products: 'Andre produkter',
    select_next_order: {
      heading: 'Hvornår vil du have den næste levering?',
    },
    x_kg_y_weeks: '{{kg}} kg hver<br/>\n{{weeks}}. uge',
    explainer:
      '<div class="drop-letter">💡 Vi har sammensat følgende abonnement til {{dogName}}, så du ikke skal bekymre dig om at få næste levering i tide.</div>\nAbonnementet indeholder da samme produkter som din prøvepakke, men du kan lave ændring eller annuleret det som det passer dig - helt uden bindingsperiode.',
  },
  checkout_pre_footer: {
    if_you_continue: 'Hvis du fortsætter',
    join_other_pupstomers:
      'Slut dig til tusindvis af glade og sunde kunder hos Alvar.',
    summary: 'Overblik',
    tasting_package: 'Prøvepakke',
    subscription_summary: 'Abonnementsoverblik',
    this_order: 'Denne bestilling',
  },
  coupon_modal: {
    discount_successfully_applied: 'Rabatten er tilføjet din ordrer.',
    no_applicable_product_in_sub:
      'Dit abonnement har ingen produkter der er omfattet af rabatten. Du kan tilføje produkter nedenfor!',
  },
  discount_overview: {
    discount_applied: 'Rabat er anvendt',
    discount_applies_to_cheapest: 'Rabattan anvendes på det billigste produkt.',
  },
  breeders: {
    box_content: {},
  },
  no_thanks: 'Nej tak',
  back: 'Tilbage',
  link: {
    order_history: 'Ordrehistorik',
  },
  alt_text: {
    complexes: {
      digestion: 'Digestion+',
      joint_support: 'Joints & Mobility+',
      'mother_&_puppy': 'Mother & Puppy+',
      puppy: 'Puppy+',
      senior: 'Senior+',
      sensitivity: 'Sensitivity+',
      'skin_&_coat': 'Skin & Coat+',
      weight_control: 'Weight Control+',
    },
  },
  customer_service: {
    consultation: {
      body: 'Usikker på din hunds kost? Vores dyrlæge Henna kan hjælpe dig!',
      link_text: 'Book en konsultation',
    },
    contact: {
      body: 'Hvis du har brug for hjælp, assisterer {{name}} gerne! Send en besked via:',
      link_text: 'hello@alvarpet.com',
    },
    faq: {
      body: 'Hvis du har spørgsmål til dine bestillinger eller product, kan du finde svar på typiske spørgsmål på',
      link_text: 'Vores FAQ side',
    },
    heading: 'Kundesupport',
    trustpilot: {
      body: 'Er du glad for dine bestillinger og vores service?',
      link_text: 'Giv os en anmeldelse på Trustpilot',
    },
  },
  product_option: {
    out_of_stock: 'Udsolgt',
  },
  validation: {
    invalid_shipping_country: 'Ugyldigt land. Vælg venligst.',
  },
  subscription_card: {
    action: {
      activate: 'Aktiver',
      add_coupon: 'Rabatkode',
      delay_next_order: 'Udskyd næste levering',
      pay_now: 'Betal nu',
      resolve_issue: 'Løs problemet',
    },
    status: {
      on_the_way:
        'Bestillingen er på vej og burde ankomme indenfor de næste 1-3 dage.',
      cancelled: 'Abonnementet er opsagt',
      next_delivery_scheduled_for: 'Næste levering: {{date}}',
      on_hold: {
        needs_payment:
          'Der var en fejl med betalingen - Bestillingen kan ikke behandles før problemet er afhjulpet.\n',
        something_wrong:
          'Der er et problem med abonnementet. Udfyld venligst manglende information.',
      },
    },
    notification: {
      you_unlocked_a_reward:
        'Du har modtaget en ny loyalitetsfordel for dette abonnement!',
    },
  },
  subscription_list: {
    add_another_dog: 'Tilføj endnu en hund\n',
    heading: {
      order_status: 'Abonnementsstatus',
    },
  },
  change_dog: {
    select_dog: 'Vælg hund',
  },
  user: {
    start_by_adding_dog: 'Start med at tilføje en hund',
    we_miss_u: 'Vi savner dig',
    we_miss_u_lead:
      'Er det på tide med en ny bestilling? Hvis du har spørgsmål eller er i tvivl om noget, hjælper vores kundeservice gerne!',
  },
  campaign_hero: {
    product_launch: {
      deer_treat_launch: {
        body: '100% naturlige hundegodbidder af tørret vildt hjortekød 🦌 Ikke kun super lækre, men også naturlige, bæredygtige og gode for hunde. Lavet af bæredygtige svenske ingredienser: Hjortekød og intet andet.\n',
        title:
          "Vi præsenterer stolt vores nye hundegodbid, Alvar's Snack Wild Deer!\n",
      },
      bug_treat: {
        body: 'Disse godbidder produceres i Finland af lokalt dyrkede insekter 😍',
        title: 'Hvad med -20% på Bug godbidder?\n',
      },
      dental: {
        body: 'For at fejre sammenlægningen af Alvar & Kivuton: nye produkter på hylderne! Vælg mellem 3 alternativer eller prøve hele trioen.',
        title: 'Tilføj tandpleje produkter til dig abonnement',
      },
    },
  },
  modal: {
    delay_next_order: {
      heading: 'Udskyd næste levering\n',
    },
  },
  cancellation_form: {
    not_suitable: {
      try_sensitivity_products: {
        include_kibble: {
          tooltip: {
            'ALV-106':
              'Delicate: kornfri tørdofer med and og mellemstore foderpiller.',
            'ALV-107':
              'Zippy: kornfri tørfoder med protein fra fjerkræ og meget små foderpiller.',
            'ALV-108':
              'Steady: kornfri tørfoder med protein fra vildfisk og mellemstore foderpiller.',
            'ALV-108-FI':
              'Steady: kornfri tørfoder med protein fra vildfisk og mellemstore foderpiller.\n',
            'ALV-110':
              'Bug: kornfri tørfoder med insektprotein og mellemstore foderpiller',
          },
        },
      },
    },
    not_tasty: {
      try_picky_products: {
        include_kibble: {
          tooltip: {
            'ALV-107':
              'Zippy er en favorit blandt små, kræsne hunde. En kornfri opskrift baseret på kylling, og med små foderpiller fyldte med velsmag.',
            'ALV-108':
              'Steady er en favorit blandt kræsne hunde. En kornfri opskrift med vildfisk som proteinkilde, og mellemstore foderpiller.',
          },
        },
      },
    },
  },
  subscription_switcher: {
    one_time_order: {
      heading: {
        save_on_orders: 'Spar på alle leveringer',
      },
    },
    subscription: {
      body: '<b>Ikke helt klar til et abonnement?</b> Prøvepakkens pris er for den første levering i et abonnement, men du kan',
    },
  },
  cart: {
    delivery_price: 'Levering',
    total: 'I alt',
  },
  shipping_price: {
    calculated_at_checkout: 'beregnes på næste trin',
    free: 'GRATIS',
    free_over_x: 'Gratis for bestillinger over {{minimumSpend}}\n',
  },
  dog_info_title: 'Din hunds informationer',
  opt_in_modal: {
    body: 'Få eksklusive tilbud og bliv informeret om nye produkter før alle andre.',
    close: 'Nej tak',
    heading: 'Vil du have eksklusive tilbud?',
    submit: 'Ja tak!',
  },
  analytical_constituents: 'Analytiske bestanddele\n',
  ash: 'Aske',
  close: 'Luk',
  fat: 'Fedt',
  fiber: 'Råfibre',
  protein: 'Protein',
  subscription_status: {
    active: 'Aktiv',
  },
  calcium: 'Calcium',
  magnesium: 'Magnesium',
  moisture: 'Vand',
  omega_3: 'Omega-3',
  omega_6: 'Omega-6',
  phosphorus: 'Fosfor',
  potassium: 'Kalium\n',
  skip_order: {
    body: 'Din næste levering vil blive behandlet <b>{{nextOrder}}<b/> og ankommer 1-3 dage efter.',
    heading: 'Er du sikker?',
  },
  sodium: 'Natrium',
};
