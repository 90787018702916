import { WizardFormValues } from '@components/forms/Recommendation/types';
import {
  Delivery,
  WPDogPost,
  Subscription,
  ShippingCountry,
  PricesData,
} from '../../types';

interface UpdateSubscriptionsProps {
  token: string;
  payload: {
    subscriptions: ((Omit<Subscription, 'id'> | Subscription) & {
      coupon_code?: string;
    })[];
    shipping_country?: ShippingCountry | null;
  };
}

export interface SubscriptionsDataProps {
  subscriptions?: Subscription[];
  delivery: Delivery;
  dogs?: WPDogPost[];
  dates: {
    last_payment: string | null | false;
    next_payment: string | null | false;
    add_payment: string | null | false;
  };
  notices?: {
    payment_method_missing: boolean;
    payment_failed: boolean;
    some_subscription_needs_payment: boolean;
  };
  loading?: boolean;
}

export const updateSubscriptions = async ({
  token,
  payload,
}: UpdateSubscriptionsProps): Promise<SubscriptionsDataProps | null> => {
  const updateResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/subscriptions`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload }),
    },
  )
    .then(async (r) => (r.ok ? await r.json() : null))
    .catch((e) => {
      reportError(e);
      return null;
    });

  return updateResponse;
};

export const getSubscriptionPrices = async ({
  token,
  payload,
}: UpdateSubscriptionsProps): Promise<PricesData | null> => {
  const pricesResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/fetch-subscription-prices`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload }),
    },
  )
    .then(async (r) => (r.ok ? await r.json() : null))
    .catch((e) => {
      reportError(e);
      return null;
    });

  return pricesResponse;
};

interface UpdateDogsProps {
  token: string;
  payload: {
    dogs: WPDogPost[];
  };
}

export const updateDogs = async ({
  token,
  payload,
}: UpdateDogsProps): Promise<SubscriptionsDataProps | null> => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_WP_ALVAR_API}/dogs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...payload }),
  });

  if (res.ok) {
    const data = await res.json();
    return data;
  }

  return null;
};

interface DeleteDogsProps {
  token: string;
  payload: {
    dogs: { dog_id: number; name: string }[];
  };
}
export const deleteDogs = async ({
  token,
  payload,
}: DeleteDogsProps): Promise<SubscriptionsDataProps | null> => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_WP_ALVAR_API}/dogs`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...payload }),
  });

  if (res.ok) {
    const data = await res.json();
    return data;
  }

  return null;
};

type CreateOrderSubscription = Pick<
  Subscription,
  'dog_name' | 'dog_id' | 'gram_need' | 'interval_weeks' | 'items'
>;

interface CreateOrderPayload
  extends Pick<
    WizardFormValues,
    | 'aw_user_is_referral'
    | 'shipping_country'
    | 'shipping_method'
    | 'order_subscription'
    | 'starting_date'
    | 'interval_weeks'
    | 'first_name'
    | 'last_name'
    | 'shipping_address_1'
    | 'shipping_postcode'
    | 'shipping_city'
    | 'shipment_transport_name'
    | 'delivery_drop_point'
    | 'phone'
    | 'dog_id'
    | 'coupons'
    | 'dog_name'
    | 'breeder'
    | 'kennel_name'
  > {
  subscriptions: CreateOrderSubscription[];
  demo_coupon_codes?: string[];
}

export interface CreateOrderProps {
  token: string;
  payload: CreateOrderPayload;
}

interface CreateOrderSuccess extends SubscriptionsDataProps {
  payment_url: false | string;
}
interface CreateOrderError {
  code: string;
  message?: string;
}

export const createOrder = async ({
  token,
  payload,
}: CreateOrderProps): Promise<CreateOrderSuccess | CreateOrderError> => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/demo-order`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    },
  );

  const data = await res.json();

  if (res.ok) {
    return data;
  }

  return { code: data.code, message: data.message };
};

export const dataIsCreateOrderError = (
  data: unknown,
): data is CreateOrderError =>
  typeof (data as { code?: string }).code === 'string' &&
  (!(data as { message?: string }).message ||
    typeof (data as { message?: string }).message === 'string');
